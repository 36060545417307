import {
  GridColumnTemplates,
  GridColumnWidth,
} from "../RAFComponents/Grid/RAFSFGridUtils";
import { WorkspaceTemplateColumnTemplates } from "../RAFMaster/helpers/RMutils";
import { DocumentGridColumnTemplates } from "../RAFModules/ActiveContacts/Document/DocumentGridColumnTemplate";
import { TaskGridColumnTemplates } from "../RAFModules/ActiveContacts/Task/TaskGridTemplateHelper";
import { RAFEntityName } from "../constants/Common/EntityConstants";
import { getGridColumnTemplateItems } from "./ACRAFGridHelperTemplates";

export const ModuleGridColumnTemplates = (): {
  moduleName: string;
  templates: GridColumnTemplates[];
}[] => {
  let retVal: { moduleName: string; templates: GridColumnTemplates[] }[] = [];

  retVal.push({
    moduleName: RAFEntityName.Contact,
    templates: ContactGridColumnTemplates,
  });
  retVal.push({
    moduleName: RAFEntityName.Account,
    templates: CompanyGridColumnTemplates,
  });
  retVal.push({
    moduleName: RAFEntityName.Task,
    templates: TaskGridColumnTemplates,
  });
  retVal.push({
    moduleName: RAFEntityName.Email,
    templates: EmailGridColumnTemplates,
  });
  retVal.push({
    moduleName: RAFEntityName.Ticket,
    templates: TicketGridColumnTemplates,
  });
  retVal.push({
    moduleName: RAFEntityName.Deal,
    templates: DealGridColumnTemplates,
  });
  retVal.push({
    moduleName: RAFEntityName.ContentLibrary,
    templates: DocumentGridColumnTemplates,
  });
  retVal.push({
    moduleName: RAFEntityName.Portal,
    templates: WorkspaceTemplateColumnTemplates,
  });
  retVal.push({
    moduleName: "pay_item",
    templates: payItemTemplateColumnTemplates,
  });
  retVal.push({
    moduleName: "work_flow",
    templates: WorkFlowGridColumnTemplates,
  });
  retVal.push({ moduleName: "view", templates: ViewGridColumnTemplates });
  // retVal.push({ moduleName: CareEsioEntity.CareRecipient.EntityName, templates: careRecipientColumnTemplates });

  return retVal;
};

export const ContactGridColumnTemplates: GridColumnTemplates[] = [
  { key: "FirstName", value: "UserNameTemplate" },
  {
    key: "Type",
    value: getGridColumnTemplateItems.defaultDropdownTemplate.key,
  },
  { key: "Account", value: "accountLookupTemplate" },
  { key: "ModifiedDate", value: "dateTimeTemplate" },
  { key: "CreatedDate", value: "dateTimeTemplate" },
  { key: "EmailOptedout", value: "isEmailOptedOutTemplate" },
];

export const ViewGridColumnTemplates: GridColumnTemplates[] = [
  { key: "DisplayName", value: "ViewTypeViewDisplayName" },
  { key: "ViewType", value: "ViewTypeViewTemplate" },
  { key: "SettingsJson.IsPrivate", value: "visibilityViewTemplate" },
  { key: "SettingsJson.ShowInList", value: "showInListViewTemplate" },
  { key: "IsSystem", value: "isBooleanTemplate" },
  { key: "ModifiedDate", value: "updatedDateTemplate" },
];

export const CompanyGridColumnTemplates: GridColumnTemplates[] = [
  { key: "ModifiedDate", value: "dateTimeTemplate" },
  { key: "CreatedDate", value: "dateTimeTemplate" },
];

export const WorkFlowGridColumnTemplates: GridColumnTemplates[] = [
  { key: "Status", value: "workFlowStatus" },
  { key: "LaunchType", value: "workFlowStatus" },
  { key: "StatusDate", value: "dateTimeTemplate" },
];

export const RelatedEmailGridColumnTemplates: GridColumnTemplates[] = [
  //{ key: "Subject", value: "emailSubjectTemplate" },
  { key: "Date", value: "emailDateTemplate" },
];

export const EmailGridColumnTemplates: GridColumnTemplates[] = [
  { key: "Date", value: "emailDateTemplate" },
  { key: "RelatedTo", value: "emailRelatedToTemplate" },
];

export const TicketGridColumnTemplates: GridColumnTemplates[] = [
  { key: "Account", value: "accountLookupTemplate" },
  { key: "Contact", value: "contactLookupTemplate" },
  { key: "RequestOrigin", value: "dropdownTemplateAsDisc" },
  { key: "Priority", value: "dropdownTemplateAsDisc" },
  { key: "LoggedDate", value: "dateTemplate" },
  { key: "Status", value: "defaultDropdownTemplate" },
  { key: "Substatus", value: "defaultDropdownTemplate" },
  { key: "TicketType", value: "dropdownTemplateAsDisc" },
  { key: "ModifiedDate", value: "dateTimeTemplate" },
  { key: "CreatedDate", value: "dateTimeTemplate" },
];

export const DealGridColumnTemplates: GridColumnTemplates[] = [
  { key: "Account", value: "accountLookupTemplate" },
  { key: "Contact", value: "contactLookupTemplate" },
  { key: "Status", value: "defaultDropdownTemplate" },
  { key: "DealDate", value: "dateTemplate" },
  { key: "ActualClosingDate", value: "dateTemplate" },
  { key: "ModifiedDate", value: "dateTimeTemplate" },
  { key: "CreatedDate", value: "dateTimeTemplate" },
];
export const TeamListGridColumnTemplates: GridColumnTemplates[] = [
  { key: "TeamName", value: "TeamNameTemplate" },
];
export const CareRecipientColumnTemplates: GridColumnTemplates[] = [
  { key: "first_name", value: "careRecipientTemplate" },
  { key: "recipient_name", value: "careRecipientTemplate" },
];
export const CareRecipientTableColumnTemplates: GridColumnTemplates[] = [
  { key: "first_name", value: "careRecipientFirstNameTableRowTemplate" },
  { key: "recipient_name", value: "careRecipientTableRowTemplate" },
];

export const CareRecipientTableColumnWidths: GridColumnWidth[] = [
  { field: "recipient_name", width: "400px" },
  { field: "first_name", width: "400px" },
];
export const EmployeeColumnTemplates: GridColumnTemplates[] = [
  { key: "first_name", value: "employeeTemplate" },
];
export const EmployeeTableColumnTemplates: GridColumnTemplates[] = [
  { key: "first_name", value: "employeeTableRowTemplate" },
  { key: "recipient_name", value: "employeeTableRowTemplate" },
  { key: "integration_ref", value: "employeeIntegrationRefTemplate" },
];

export const EmployeeTableColumnWidths: GridColumnWidth[] = [
  { field: "recipient_name", width: "400px" },
  { field: "first_name", width: "400px" },
];

export const ComplaintGridColumnTemplates: GridColumnTemplates[] = [
  { key: "Account", value: "accountLookupTemplate" },
  { key: "Contact", value: "contactLookupTemplate" },
  { key: "ModifiedDate", value: "dateTimeTemplate" },
  { key: "CreatedDate", value: "dateTimeTemplate" },
];

export const EntityColumnTemplate: GridColumnTemplates[] = [
  { key: "DisplayName", value: "entityColumnTemplate" },
];

export const PageListColumnTemplate: GridColumnTemplates[] = [
  { key: "Name", value: "pageListColumnTemplate" },
];

export const PageRuleColumnTemplate: GridColumnTemplates[] = [
  { key: "RuleName", value: "pageRuleColumnTemplate" },
];

export const payItemTemplateColumnTemplates: GridColumnTemplates[] = [
  { key: "IntegrationRef", value: "employeeIntegrationRefTemplate" },
];

export const ComplianceListColumnTemplates: GridColumnTemplates[] = [
  { key: "Title", value: "complainceListRowTemplate" },
  { key: "RelatedTo", value: "relatedTaskToLookupTemplate" },
];

export const ComplianceTableColumnTemplates: GridColumnTemplates[] = [
  { key: "Title", value: "complainceTableRowTemplate" },
  { key: "RelatedTo", value: "relatedTaskToLookupTemplate" },
  { key: "ExpiryDate", value: "expiryDateTemplate" },
];

export const ComplianceTableColumnWidths: GridColumnWidth[] = [
  { field: "Title", width: "400px" },
  { field: "RelatedTo", width: "300px" },
  { field: "ExpiryDate", width: "200px" },
];

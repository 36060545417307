import { Browser } from "@syncfusion/ej2-base";
import { MenuEventArgs } from "@syncfusion/ej2-navigations";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import DOMPurify from "dompurify";
import moment from "moment";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { RRule } from "rrule";
import CustomCardWidget from "../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFIconImage from "../RAFComponents/Navigation/RAFIconImage";
import RAFRecordInfo from "../RAFComponents/Navigation/RAFRecordInfo";
import RAFPermissionRender from "../RAFComponents/helpers/PermissionHelper";
import { onLookupLinkClicked } from "../RAFComponents/helpers/RAFMenuHelper";
import {
  ConvertSystemName,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  calculateAge,
  getDate,
  hexToRGBA,
  isArray,
  isEmptyOrNull,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  joinStringArray,
  statesMessageContent,
} from "../RAFComponents/helpers/utils";
import { RAFFormType } from "../RAFComponents/models/Common/FormLibraryRow";
import {
  QueryAttributeJM,
  ValueJson,
} from "../RAFComponents/models/Common/QueryAttributeJM";
import {
  RAFDataType,
  RAFUIType,
} from "../RAFComponents/models/Common/RAFDataType";
import RAFBPProgressBar from "../RAFMaster/RMModules/BusinessProcess/Process/RAFBPProgressBar";
import { getEntityDisplayNameByModuleName } from "../RAFMaster/RMModules/Category/BusinessProcessTypeHelper";
import { RAFFormLibraryTabName } from "../RAFMaster/RMModules/FormLibrary/FormLibaryHelper";
import {
  DocumentCategoryTemplate,
  DocumentTableCategoryTemplate,
  documentActionButtonTemplate,
  documentDisplayNameListTemplate,
  documentDisplayNameTemplate,
  documentFileSizeTemplate,
  documentRowDisplayNameTemplate,
  documentTableDisplayNameTemplate,
  relatedDocumentRowDisplayNameTemplate,
} from "../RAFModules/ActiveContacts/Document/DocumentGridColumnTemplate";
import {
  revisionListTemplate,
  revisionTemplate,
  versionHistoryListTemplate,
  versionHistoryTemplate,
  checklistItemTransVersionHistoryListTemplate,
} from "../RAFModules/ActiveContacts/Document/DocumentVersionHistory/DocumentVersionHistoryTemplate";
import {
  relatedTaskToLookupTemplate,
  taskDateColumnTemplate,
  taskStatusTemplate,
  taskTitleColumnTemplate,
  taskTitleRowTemplate,
  taskTitleWithReassigneeColumnTemplate,
  taskTitleWithReassigneeRowTemplate,
} from "../RAFModules/ActiveContacts/Task/TaskGridTemplateHelper";
import UserProfilePhoto from "../RAFModules/ActiveContacts/User/UserProfilePhoto";
import { CareEsioEntity } from "../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFButtonConstant,
  RAFGenderConstant,
  RAFStatusNameWithColor,
} from "../constants/Common/Constants";
import { RAFEntityName } from "../constants/Common/EntityConstants";
import { PermissionConstants } from "../constants/Common/PermissionConstants";
import { RAFBPCurrentStatus } from "../constants/Common/RMConstants";
import xeroImgSrc from "./../assets/Xero/Logo - Blue.png";
import {
  bodypartpickerTemplate,
  dateGridTemplate,
  dateTimeGridTemplate,
  defaultDropdownGridTemplate,
  descriptionGridTemplate,
  firstColumnGridTemplate,
  isBooleanGridTemplate,
  lookupGridTemplate,
  lookupMultiSelectGridTemplate,
  riskmatrixJsonTemplate,
  tagsGridTemplate,
  updatedDateTemplate,
} from "./ACDefaultRAFGridHelperTemplates";
import {
  CareEmployeePermissionConstants,
  CareRecipientPermissionConstants,
} from "../constants/CareESIO/CareESIOPermissionConstant";

export const statusDropdownTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  let fieldValue = data[data.column.field];

  if (isNotNullAndUndefined(fieldValue)) {
    //let currentChoiceList = item && item.ValueJson && item.ValueJson.find(x => x.Name === fieldValue);
    if (item.IsMultiSelect === true) {
      let objectValue: string[] = fieldValue;
      let retVal: string[] = [];
      if (isArray(objectValue)) {
        objectValue.forEach((objVal) => {
          let obj =
            item.ValueJson &&
            item.ValueJson.find(
              (x) => x.DisplayName === objVal || x.Name === objVal
            );
          retVal.push(obj && obj.DisplayName);
        });
        fieldValue = isNotNullAndUndefined(retVal) ? retVal.join(", ") : "NA";
      }

      return (
        <div>
          <div className={"custom_key_value_outter"}>
            <div className="custom_key_value e-badge">
              <span> {fieldValue} </span>
            </div>
            {/* {showEdit === true && (
                <div className="gridEditBtn noRowclick">
                  <ButtonComponent
                    type="button"
                    className="e-flat btndefault-icon e-editbutton"
                    iconCss="fal fa-pencil"
                  />
                </div>
              )} */}
          </div>
        </div>
      );
    } else if (
      item.UIType !== RAFUIType.ToggleButton &&
      item.UIType !== RAFUIType.SimpleDropdown
    ) {
      let currentChoiceList = isNotEmptyArray(item.ValueJson)
        ? item.ValueJson.find(
            (x) => x.DisplayName === fieldValue || x.Name === fieldValue
          )
        : null;

      let rafChoiceList = RAFStatusNameWithColor[fieldValue];

      if (isNullOrUndefined(currentChoiceList)) {
        let defaultValue: ValueJson = {
          ColorCode: "#8e24aa",
          DisplayName: fieldValue,
          Id: 99,
          Name: fieldValue,
        };
        currentChoiceList = defaultValue;
      }

      if (isNotNullAndUndefined(rafChoiceList)) {
        return (
          <div>
            <div className={"custom_key_value_outter"}>
              <div
                style={{
                  background: rafChoiceList.Color,
                  color: IsNullOrWhiteSpace(rafChoiceList.Color)
                    ? "white"
                    : null,
                }}
                className="custom_key_value e-badge"
              >
                <span> {rafChoiceList.DisplayName} </span>
              </div>
              {/* {showEdit === true && (
                    <div className="gridEditBtn noRowclick">
                      <ButtonComponent
                        type="button"
                        className="e-flat btndefault-icon e-editbutton"
                        iconCss="fal fa-pencil"
                      />
                    </div>
                  )} */}
            </div>
          </div>
        );
      }
    } else {
      let currentChoiceList = isNotEmptyArray(item.ValueJson)
        ? item.ValueJson.find(
            (x) => x.DisplayName === fieldValue || x.Name === fieldValue
          )
        : null;
      if (isNotNullAndUndefined(currentChoiceList)) {
        return <span> {currentChoiceList.DisplayName} </span>;
      } else {
        return <span> {fieldValue} </span>;
      }
    }
  }

  return;
};

export const dropdownTemplateAsDisc = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldValue = data[data.column.field];
  if (isNotNullAndUndefined(fieldValue)) {
    //let currentChoiceList = item && item.ValueJson && item.ValueJson.find(x => x.Name === fieldValue);
    let currentChoiceList =
      item &&
      item.ValueJson &&
      item.ValueJson.find((x) => x.DisplayName === fieldValue);
    if (isNotNullAndUndefined(currentChoiceList)) {
      return (
        <div className="d-flex align-items-center colorIcon">
          <i
            className="fas fa-circle"
            style={{
              color: isNotNullAndUndefined(currentChoiceList.ColorCode)
                ? currentChoiceList.ColorCode
                : "",
            }}
          ></i>
          <span className="custom_key_value">
            {currentChoiceList.DisplayName}
          </span>
          {showEdit === true && (
            <div className="gridEditBtn noRowclick">
              <ButtonComponent
                type="button"
                className="e-flat btndefault-icon e-editbutton"
                iconCss="fal fa-pencil"
              />
            </div>
          )}
        </div>
      );
    }
  }

  return;
};

export const dropdownTemplateAsSquare = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldValue = data[data.column.field];
  if (isNotNullAndUndefined(fieldValue)) {
    //let currentChoiceList = item && item.ValueJson && item.ValueJson.find(x => x.Name === fieldValue);
    let currentChoiceList =
      item &&
      item.ValueJson &&
      item.ValueJson.find((x) => x.DisplayName === fieldValue);
    if (isNotNullAndUndefined(currentChoiceList)) {
      return (
        <div className="d-flex align-items-center colorIcon custom_dropdown">
          <i
            className="fas fa-square"
            style={{
              color: isNotNullAndUndefined(currentChoiceList.ColorCode)
                ? currentChoiceList.ColorCode
                : "",
            }}
          ></i>
          <span className="custom_key_value e-badge">
            {currentChoiceList.DisplayName}
          </span>
          {showEdit === true && (
            <div className="gridEditBtn noRowclick">
              <ButtonComponent
                type="button"
                className="e-flat btndefault-icon e-editbutton"
                iconCss="fal fa-pencil"
              />
            </div>
          )}
        </div>
      );
    }
  }

  return;
};

export const isActiveTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldValue = data[data.column.field];
  let displayText = "False";
  if (isNotNullAndUndefined(fieldValue) && fieldValue) {
    displayText = "Yes";
  } else {
    displayText = "No";
  }
  if (isNotNullAndUndefined(displayText)) {
    return (
      <div className="gridDefault d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center noRowclick w-100">
          <div className="gridTempleteTextOuter">
            <span className="ms-2" style={{ cursor: "pointer" }}>
              {displayText}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return <span>{"No"}</span>;
};

export const accountLookupTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  if (isNotNullAndUndefined(fieldValue)) {
    let valueUID = data[fieldName + "UID"];
    return (
      <div
        className={
          isNotNullAndUndefined(valueUID)
            ? "gridDefault d-flex align-items-center justify-content-between raf-account-relatedto noRowclick"
            : "gridDefault d-flex align-items-center justify-content-between raf-account-relatedto"
        }
        onClick={() =>
          onLookupLinkClicked(
            RAFEntityName.Account,
            valueUID,
            isDynamic,
            navigate
          )
        }
      >
        <div className="d-flex align-items-center w-100 overflow-auto">
          {!Browser.isDevice && (
            <span
              className="avatar-text-square company-avatar"
              style={{
                width: "24px",
                height: "24px",
                minWidth: "24px",
                maxWidth: "24px",
                fontSize: "12px",
              }}
            >
              <span className="fa fa-building"></span>
            </span>
          )}
          <div className="gridTempleteTextOuter">
            <span
              className={!Browser.isDevice ? "ms-2" : ""}
              style={{
                color: !Browser.isDevice ? "#0078d6" : "#333",
                cursor: "pointer",
              }}
            >
              {fieldValue}
            </span>
          </div>
        </div>
        <div
          className="gridEditBtn noRowclick"
          onClick={(e) => e.stopPropagation()}
        >
          {!Browser.isDevice && (
            <ButtonComponent
              type="button"
              className="grid-square-btn"
              iconCss="far fa-arrow-up-right-from-square"
              onClick={() =>
                onLookupLinkClicked(RAFEntityName.Account, valueUID, isDynamic)
              }
            />
          )}
          {/* {showEdit === true && allowEditing === true && (
                <ButtonComponent
                  type="button"
                  className="e-flat btndefault-icon e-editbutton unset-custom-button-md"
                  iconCss="fal fa-pencil"
                />
              )} */}
        </div>
      </div>
    );
  }
  return;
};

export const contactLookupTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldValue = data[data.column.field];
  //let initial = fieldValue && fieldValue.charAt(0).toUpperCase();
  if (isNotNullAndUndefined(fieldValue)) {
    let valueUID = data[data.column.field + "UID"];
    return (
      <div
        className={
          isNotNullAndUndefined(valueUID)
            ? "gridDefault d-flex align-items-center justify-content-between raf-contact-relatedto noRowclick"
            : "gridDefault d-flex align-items-center justify-content-between raf-contact-relatedto"
        }
        onClick={() =>
          onLookupLinkClicked(
            RAFEntityName.Contact,
            valueUID,
            isDynamic,
            navigate
          )
        }
      >
        <div className="d-flex align-items-center w-100 overflow-auto">
          {/*<span className={"avatar-text-circle avatar-initial-palettes-" + initial} style={{
                          width: "30px",
                          height: "30px",
                          minWidth: "30px",
                          maxWidth: "30px",
                          fontSize: "12px", fontWeight: 400, marginRight:'10px'
                      }}>{initial}</span>*/}
          {!Browser.isDevice && (
            <span
              className={"avatar-text-square contact-avatar"}
              style={{
                width: "24px",
                height: "24px",
                minWidth: "24px",
                maxWidth: "24px",
                fontSize: "12px",
              }}
            >
              <span className="fas fa-address-card"></span>
            </span>
          )}
          <div className="gridTempleteTextOuter">
            <span
              className={!Browser.isDevice ? "ms-2" : ""}
              style={{
                color: !Browser.isDevice ? "#0078d6" : "#333",
                cursor: "pointer",
              }}
            >
              {fieldValue}
            </span>
          </div>
        </div>
        <div
          className="gridEditBtn noRowclick"
          onClick={(e) => e.stopPropagation()}
        >
          {!Browser.isDevice && (
            <ButtonComponent
              type="button"
              className="grid-square-btn"
              iconCss="far fa-arrow-up-right-from-square"
              onClick={() =>
                onLookupLinkClicked(RAFEntityName.Contact, valueUID, isDynamic)
              }
            />
          )}
          {/* {showEdit === true && allowEditing === true && (
                <ButtonComponent
                  type="button"
                  className="e-flat btndefault-icon e-editbutton link-button unset-custom-button-md"
                  iconCss="fal fa-pencil"
                />
              )} */}
        </div>
      </div>
    );
  }
  return;
};

export const careRecipientTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const UID = data["UID"];
  let address = isNotNullAndUndefined(data["address"])
    ? JSON.parse(data["address"])
    : null;
  const city = isNotNullAndUndefined(address) ? address["city"] : "";
  const PostCode = isNotNullAndUndefined(address) ? address["postal_code"] : "";
  const firstName =
    isNotNullAndUndefined(data["first_name"]) ||
    isNotNullAndUndefined(data["middle_name"]) ||
    isNotNullAndUndefined(data["last_name"])
      ? data["first_name"]
      : "Not set";
  const middleName = data["middle_name"];
  const lastName = data["last_name"];
  const Email = data["email"];
  const CurrentGender = data["current_gender"];
  const currectGenderConstant =
    RAFGenderConstant[ConvertSystemName(CurrentGender)];
  const PrimaryPhone = isNotNullAndUndefined(data["primary_phone"])
    ? data["primary_phone"]
    : data["other_phone"];
  const carePlanTitle = joinStringArray([firstName, middleName, lastName]);
  let dOBDate = data["dob"];
  let age = calculateAge(dOBDate);
  // if (isNotNullAndUndefined(dOBDate)) {
  //   // dOBDate = convertUTCDateToLocalTimezone(dOBDate);
  //   // dOBDate = moment(dOBDate).format("DD/MM/YYYY");
  //   const currentDate = moment();
  //   const dob = moment(dOBDate);
  //   const diffDuration = moment.duration(currentDate.diff(dob));

  //   if (diffDuration.years() > 0) {
  //     if (diffDuration.years() > 1) {
  //       age = `${diffDuration.years()} Years`;
  //     }
  //     else {
  //       age = `${diffDuration.years()} Year`;
  //     }
  //   }
  //   else if (diffDuration.months() > 0) {
  //     if (diffDuration.months() > 1) {
  //       age = `${diffDuration.months()} Months`;
  //     }
  //     else {
  //       age = `${diffDuration.months()} Month`;
  //     }
  //   }
  //   else {
  //     if (diffDuration.days() > 1) {
  //       age = `${diffDuration.days()} Days`;
  //     }
  //     else {
  //       age = `${diffDuration.days()} Day`;
  //     }
  //   }
  // }

  // if (isNotNullAndUndefined(fieldValue)) {
  let menuItems = [];

  return (
    <div className="listGridItemOutter">
      <div className="listGridItemContent">
        <div className="listGridItem">
          <div className={""}>
            {/* <img
                      src={avatarImage}
                      alt="Avatar Image"
                      className="avatarLogoImage"
                  />
                   */}
            <div className="" id={`care_Recipient_${UID}`}>
              <div className="row gx-3">
                <div className="col-auto">
                  <UserProfilePhoto
                    uid={UID}
                    relatedToType={CareEsioEntity.CareRecipient.EntityName}
                    canEdit={false}
                    profileImgCss={"profileImg-container-normal"}
                    progressDivID={`#care_Recipient_${UID}`}
                    title={carePlanTitle ?? "Not Set"}
                    iconSize="40"
                    fontSize="16"
                    emptyAvatarType="Initial"
                  />
                </div>
                <div className="col ps-1 overflow-auto">
                  <div className="subtitle_1">
                    <span className="subtitle_1 ecllipseFirstLine text-wrap word-break-all semi_bold">
                      {carePlanTitle}
                    </span>
                  </div>
                  {isNotNullAndUndefined(dOBDate) ? (
                    <div className="d-flex">
                      <div className="body_2_dark ecllipseFirstLine text-wrap word-break-all">
                        {age}
                      </div>
                      <div className="mx-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="3"
                          height="4"
                          viewBox="0 0 3 4"
                          fill="none"
                        >
                          <circle cx="1.5" cy="2" r="1.5" fill="#A3A3A3" />
                        </svg>
                      </div>
                      <div className="body_2_dark ecllipseFirstLine text-wrap word-break-all">
                        {getDate(dOBDate, MomentFormats.DATE)}
                      </div>
                    </div>
                  ) : (
                    <div className="body_2_dark">Not set</div>
                  )}
                </div>
                <div className="col-auto noRowclick">
                  <DropDownButtonComponent
                    items={[
                      { id: "Edit", text: "Edit" },
                      { id: "Delete", text: "Delete" },
                    ]}
                    cssClass="bg-transparent border-0 e-caret-hide"
                    select={(selectedMenu) => {
                      if (onActionMenuClicked) {
                        onActionMenuClicked(data, selectedMenu);
                      }
                    }}
                    iconCss={RAFButtonConstant.More.IconCss}
                  ></DropDownButtonComponent>
                </div>
              </div>
            </div>
            <div className="mt-3 custom__card__separator custom__card__separator__light"></div>
            <div className="py-2 d-flex align-items-center">
              <div
                style={{ width: "1rem", height: "1rem" }}
                className="d-flex align-items-center"
              >
                <i
                  className={`content_brand_secondary_base ${
                    isNotNullAndUndefined(currectGenderConstant)
                      ? currectGenderConstant.iconCss_l
                      : RAFGenderConstant.male.iconCss_l
                  }`}
                ></i>
              </div>
              <span className="ps-2 body_2_dark">
                <RAFDetailFieldCustom
                  value={CurrentGender}
                  title="Gender"
                  valueDivClassName="col-auto"
                  valueClassName="body_2_dark"
                  rowClassName="gx-1 align-items-baseline flex-nowrap"
                  field="CurrentGender"
                  moduleName={CareEsioEntity.CareRecipient.EntityName}
                  showLabel={false}
                  fieldFormat={{ type: RAFDataType.Dropdown }}
                />
              </span>
            </div>
            <div className="custom__card__separator custom__card__separator__light"></div>
            <div className="py-2 d-flex align-items-center">
              <i
                className="fal fa-phone content_brand_secondary_base"
                style={{ width: "1rem", height: "1rem" }}
              ></i>
              <span className="ps-2 body_2_dark">
                {PrimaryPhone ?? "Not set"}
              </span>
            </div>
            <div className="custom__card__separator custom__card__separator__light"></div>
            <div className="pt-2 d-flex align-items-center">
              <i
                className="fasl fa-location-dot content_brand_secondary_base"
                style={{ width: "1rem", height: "1rem" }}
              ></i>
              <span className="ps-2 body_2_dark">
                {IsNullOrWhiteSpace(city) && IsNullOrWhiteSpace(PostCode)
                  ? "Not set"
                  : `${city ?? ""}${
                      IsNotNullOrWhiteSpace(city) &&
                      IsNotNullOrWhiteSpace(PostCode)
                        ? `, `
                        : ""
                    }${PostCode ?? ""}`}
              </span>
            </div>
            {/* <div
                  className="detailsLeftCard-second-title line-seprator h-auto"
                  style={{ padding: "2px 0" }}
                >
                  <div className="row gx-2 flex-nowrap align-items-center">
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        <span>{isNotNullAndUndefined(age) ? age : "Age"}</span>
                      </div>
                    </div>
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        {isNotNullAndUndefined(CurrentGender) ? (
                          <RAFDetailFieldCustom
                            value={CurrentGender}
                            title="Gender"
                            valueDivClassName="col-auto"
                            valueClassName="details-value-light ecllipseFirstLine"
                            rowClassName="gx-1 align-items-baseline flex-nowrap"
                            field="CurrentGender"
                            moduleName={CareEsioEntity.CareRecipient.EntityName}
                            showLabel={false}
                            fieldFormat={{ type: RAFDataType.Dropdown }}
                          />
                        ) : (
                          "Gender"
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}

            {/* <div className="detailsLeftCard-second-title h-auto">
                  <div className="row gx-2 flex-nowrap align-items-center">
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        <RAFDetailFieldCustom
                          value={PrimaryPhone}
                          title="Phone"
                          labelIconName="fas fa-phone"
                          valueDivClassName="col-auto"
                          valueClassName="details-value-light ecllipseFirstLine"
                          rowClassName="gx-1 align-items-baseline flex-nowrap"
                        />
                      </div>
                    </div>
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        {isNotNullAndUndefined(city) ? (
                          <RAFDetailFieldCustom
                            value={city}
                            title="City"
                            labelIconName="fas fa-city"
                            valueDivClassName="col-auto"
                            valueClassName="details-value-light ecllipseFirstLine"
                            rowClassName="gx-1 align-items-baseline flex-nowrap"
                          />
                        ) : (
                          "City"
                        )}
                      </div>
                    </div>
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        {isNotNullAndUndefined(PostCode) ? (
                          <RAFDetailFieldCustom
                            value={PostCode}
                            title="PostCode"
                            labelIconName="fas fa-map-pin"
                            valueDivClassName="col-auto"
                            valueClassName="details-value-light ecllipseFirstLine"
                            rowClassName="gx-1 align-items-baseline flex-nowrap"
                          />
                        ) : (
                          "PostCode"
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}
          </div>
        </div>
      </div>
    </div>
  );
  // }
  // return <div> {"\u00A0"}</div>;
};

export const careRecipientFirstNameTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const UID = data["UID"];
  let address = isNotNullAndUndefined(data["address"])
    ? JSON.parse(data["address"])
    : null;
  const city = isNotNullAndUndefined(address) ? address["city"] : "";
  const PostCode = isNotNullAndUndefined(address) ? address["postal_code"] : "";
  const firstName = isNotNullAndUndefined(data["first_name"])
    ? data["first_name"]
    : "Not set";
  const middleName = data["middle_name"];
  const lastName = data["last_name"];
  const Email = data["email"];
  const CurrentGender = data["current_gender"];
  const currectGenderConstant =
    RAFGenderConstant[ConvertSystemName(CurrentGender)];
  const PrimaryPhone = isNotNullAndUndefined(data["primary_phone"])
    ? data["primary_phone"]
    : data["other_phone"];
  const carePlanTitle = joinStringArray([firstName, middleName, lastName]);
  let dOBDate = data["dob"];
  let age = calculateAge(dOBDate);
  // if (isNotNullAndUndefined(dOBDate)) {
  //   // dOBDate = convertUTCDateToLocalTimezone(dOBDate);
  //   // dOBDate = moment(dOBDate).format("DD/MM/YYYY");
  //   const currentDate = moment();
  //   const dob = moment(dOBDate);
  //   const diffDuration = moment.duration(currentDate.diff(dob));

  //   if (diffDuration.years() > 0) {
  //     if (diffDuration.years() > 1) {
  //       age = `${diffDuration.years()} Years`;
  //     }
  //     else {
  //       age = `${diffDuration.years()} Year`;
  //     }
  //   }
  //   else if (diffDuration.months() > 0) {
  //     if (diffDuration.months() > 1) {
  //       age = `${diffDuration.months()} Months`;
  //     }
  //     else {
  //       age = `${diffDuration.months()} Month`;
  //     }
  //   }
  //   else {
  //     if (diffDuration.days() > 1) {
  //       age = `${diffDuration.days()} Days`;
  //     }
  //     else {
  //       age = `${diffDuration.days()} Day`;
  //     }
  //   }
  // }

  // if (isNotNullAndUndefined(fieldValue)) {
  let menuItems = [];

  return (
    <div className="listGridItemOutter">
      <div className="listGridItemContent">
        <div className="listGridItem">
          <div className={""}>
            {/* <img
                      src={avatarImage}
                      alt="Avatar Image"
                      className="avatarLogoImage"
                  />
                   */}
            <div className="" id={`care_Recipient_${UID}`}>
              <div className="row gx-3">
                <div className="col-auto">
                  <UserProfilePhoto
                    uid={UID}
                    relatedToType={CareEsioEntity.CareRecipient.EntityName}
                    canEdit={false}
                    profileImgCss={"profileImg-container-normal"}
                    progressDivID={`#care_Recipient_${UID}`}
                    title={firstName ?? "Not Set"}
                    iconSize="40"
                    fontSize="16"
                    emptyAvatarType="Initial"
                  />
                </div>
                <div className="col ps-1 overflow-auto">
                  <div className="subtitle_1">
                    <span className="subtitle_1 ecllipseFirstLine text-wrap word-break-all semi_bold">
                      {firstName}
                    </span>
                  </div>
                  {isNotNullAndUndefined(dOBDate) ? (
                    <div className="d-flex">
                      <div className="body_2_dark ecllipseFirstLine text-wrap word-break-all">
                        {age}
                      </div>
                      <div className="mx-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="3"
                          height="4"
                          viewBox="0 0 3 4"
                          fill="none"
                        >
                          <circle cx="1.5" cy="2" r="1.5" fill="#A3A3A3" />
                        </svg>
                      </div>
                      <div className="body_2_dark ecllipseFirstLine text-wrap word-break-all">
                        {getDate(dOBDate, MomentFormats.DATE)}
                      </div>
                    </div>
                  ) : (
                    <div className="body_2_dark">Not set</div>
                  )}
                </div>
                <div className="col-auto noRowclick">
                  <DropDownButtonComponent
                    items={[
                      { id: "Edit", text: "Edit" },
                      { id: "Delete", text: "Delete" },
                    ]}
                    cssClass="bg-transparent border-0 e-caret-hide"
                    select={(selectedMenu) => {
                      if (onActionMenuClicked) {
                        onActionMenuClicked(data, selectedMenu);
                      }
                    }}
                    iconCss={RAFButtonConstant.More.IconCss}
                  ></DropDownButtonComponent>
                </div>
              </div>
            </div>
            <div className="mt-3 custom__card__separator custom__card__separator__light"></div>
            <div className="py-2 d-flex align-items-center">
              <div
                style={{ width: "1rem", height: "1rem" }}
                className="d-flex align-items-center"
              >
                <i
                  className={`content_brand_secondary_base ${
                    isNotNullAndUndefined(currectGenderConstant)
                      ? currectGenderConstant.iconCss_l
                      : RAFGenderConstant.male.iconCss_l
                  }`}
                ></i>
              </div>
              <span className="ps-2 body_2_dark">
                <RAFDetailFieldCustom
                  value={CurrentGender}
                  title="Gender"
                  valueDivClassName="col-auto"
                  valueClassName="body_2_dark"
                  rowClassName="gx-1 align-items-baseline flex-nowrap"
                  field="CurrentGender"
                  moduleName={CareEsioEntity.CareRecipient.EntityName}
                  showLabel={false}
                  fieldFormat={{ type: RAFDataType.Dropdown }}
                />
              </span>
            </div>
            <div className="custom__card__separator custom__card__separator__light"></div>
            <div className="py-2 d-flex align-items-center">
              <i
                className="fal fa-phone content_brand_secondary_base"
                style={{ width: "1rem", height: "1rem" }}
              ></i>
              <span className="ps-2 body_2_dark">
                {PrimaryPhone ?? "Not set"}
              </span>
            </div>
            <div className="custom__card__separator custom__card__separator__light"></div>
            <div className="pt-2 d-flex align-items-center">
              <i
                className="fasl fa-location-dot content_brand_secondary_base"
                style={{ width: "1rem", height: "1rem" }}
              ></i>
              <span className="ps-2 body_2_dark">
                {IsNullOrWhiteSpace(city) && IsNullOrWhiteSpace(PostCode)
                  ? "Not set"
                  : `${city ?? ""}${
                      IsNotNullOrWhiteSpace(city) &&
                      IsNotNullOrWhiteSpace(PostCode)
                        ? `, `
                        : ""
                    }${PostCode ?? ""}`}
              </span>
            </div>
            {/* <div
                  className="detailsLeftCard-second-title line-seprator h-auto"
                  style={{ padding: "2px 0" }}
                >
                  <div className="row gx-2 flex-nowrap align-items-center">
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        <span>{isNotNullAndUndefined(age) ? age : "Age"}</span>
                      </div>
                    </div>
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        {isNotNullAndUndefined(CurrentGender) ? (
                          <RAFDetailFieldCustom
                            value={CurrentGender}
                            title="Gender"
                            valueDivClassName="col-auto"
                            valueClassName="details-value-light ecllipseFirstLine"
                            rowClassName="gx-1 align-items-baseline flex-nowrap"
                            field="CurrentGender"
                            moduleName={CareEsioEntity.CareRecipient.EntityName}
                            showLabel={false}
                            fieldFormat={{ type: RAFDataType.Dropdown }}
                          />
                        ) : (
                          "Gender"
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}

            {/* <div className="detailsLeftCard-second-title h-auto">
                  <div className="row gx-2 flex-nowrap align-items-center">
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        <RAFDetailFieldCustom
                          value={PrimaryPhone}
                          title="Phone"
                          labelIconName="fas fa-phone"
                          valueDivClassName="col-auto"
                          valueClassName="details-value-light ecllipseFirstLine"
                          rowClassName="gx-1 align-items-baseline flex-nowrap"
                        />
                      </div>
                    </div>
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        {isNotNullAndUndefined(city) ? (
                          <RAFDetailFieldCustom
                            value={city}
                            title="City"
                            labelIconName="fas fa-city"
                            valueDivClassName="col-auto"
                            valueClassName="details-value-light ecllipseFirstLine"
                            rowClassName="gx-1 align-items-baseline flex-nowrap"
                          />
                        ) : (
                          "City"
                        )}
                      </div>
                    </div>
                    <div className="col-auto detailsLeftCard-second-title-col">
                      <div className="detailsLeftCard-second-title-item">
                        {isNotNullAndUndefined(PostCode) ? (
                          <RAFDetailFieldCustom
                            value={PostCode}
                            title="PostCode"
                            labelIconName="fas fa-map-pin"
                            valueDivClassName="col-auto"
                            valueClassName="details-value-light ecllipseFirstLine"
                            rowClassName="gx-1 align-items-baseline flex-nowrap"
                          />
                        ) : (
                          "PostCode"
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}
          </div>
        </div>
      </div>
    </div>
  );
  // }
  // return <div> {"\u00A0"}</div>;
};

export const careRecipientTableRowTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const UID = data["UID"];
  const firstName =
    isNotNullAndUndefined(data["first_name"]) ||
    isNotNullAndUndefined(data["middle_name"]) ||
    isNotNullAndUndefined(data["last_name"])
      ? data["first_name"]
      : "Not set";
  const middleName = data["middle_name"];
  const lastName = data["last_name"];
  const carePlanTitle = joinStringArray([firstName, middleName, lastName]);
  return (
    <div className="" id={`care_Recipient_${UID}`}>
      <div className="row gx-3 flex-nowrap align-items-center">
        <div className="col-auto">
          <UserProfilePhoto
            uid={UID}
            relatedToType={CareEsioEntity.CareRecipient.EntityName}
            canEdit={false}
            profileImgCss={"profileImg-container-xsm"}
            progressDivID={`#care_Recipient_${UID}`}
            title={carePlanTitle ?? "Not Set"}
            iconSize="24"
            fontSize="10"
            emptyAvatarType="Initial"
          />
        </div>
        <div className="col ps-1">
          <div className="body_1_dark">
            <span className="body_1_dark ecllipseSecondLine">
              {fieldValue ?? "Not Set"}
            </span>
          </div>
        </div>
        <div className="col-auto noRowclick">
          <DropDownButtonComponent
            items={[
              { id: "Edit", text: "Edit" },
              { id: "Delete", text: "Delete" },
            ]}
            cssClass="bg-transparent border-0 e-caret-hide"
            select={(selectedMenu) => {
              if (onActionMenuClicked) {
                onActionMenuClicked(data, selectedMenu);
              }
            }}
            iconCss={RAFButtonConstant.More.IconCss}
          ></DropDownButtonComponent>
        </div>
      </div>
    </div>
  );
};

export const careRecipientFirstNameTableRowTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const UID = data["UID"];
  const firstName = isNotNullAndUndefined(data["first_name"])
    ? data["first_name"]
    : "Not set";
  const middleName = data["middle_name"];
  const lastName = data["last_name"];
  const carePlanTitle = joinStringArray([firstName, middleName, lastName]);
  return (
    <div className="" id={`care_Recipient_${UID}`}>
      <div className="row gx-3 flex-nowrap align-items-center">
        <div className="col-auto">
          <UserProfilePhoto
            uid={UID}
            relatedToType={CareEsioEntity.CareRecipient.EntityName}
            canEdit={false}
            profileImgCss={"profileImg-container-xsm"}
            progressDivID={`#care_Recipient_${UID}`}
            title={firstName ?? "Not Set"}
            iconSize="24"
            fontSize="10"
            emptyAvatarType="Initial"
          />
        </div>
        <div className="col ps-1">
          <div className="body_1_dark">
            <span className="body_1_dark ecllipseSecondLine">{firstName}</span>
          </div>
        </div>
        <div className="col-auto noRowclick">
          <DropDownButtonComponent
            items={[
              { id: "Edit", text: "Edit" },
              { id: "Delete", text: "Delete" },
            ]}
            cssClass="bg-transparent border-0 e-caret-hide"
            select={(selectedMenu) => {
              if (onActionMenuClicked) {
                onActionMenuClicked(data, selectedMenu);
              }
            }}
            iconCss={RAFButtonConstant.More.IconCss}
          ></DropDownButtonComponent>
        </div>
      </div>
    </div>
  );
};

export const employeeTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const UID = data["UID"];
  let address = isNotNullAndUndefined(data["address"])
    ? JSON.parse(data["address"])
    : null;
  const city = isNotNullAndUndefined(data) ? data["addresscity"] : "";
  const PostCode = isNotNullAndUndefined(data)
    ? data["addresspostal_code"]
    : "";
  const firstName =
    isNotNullAndUndefined(data["first_name"]) ||
    isNotNullAndUndefined(data["middle_name"]) ||
    isNotNullAndUndefined(data["last_name"])
      ? data["first_name"]
      : "Not set";
  const middleName = data["middle_name"];
  const lastName = data["last_name"];
  const Email = data["email"];
  const CurrentGender = data["gender"];
  const currectGenderConstant =
    RAFGenderConstant[ConvertSystemName(CurrentGender)];
  const PrimaryPhone = IsNotNullOrWhiteSpace(data["phonework"])
    ? data["phonework"]
    : data["phonepersonal"];
  const carePlanTitle = joinStringArray([firstName, middleName, lastName]);
  const dOBDate = data["date_of_birth"];
  const age = calculateAge(dOBDate);

  const integration_refuid = data["integration_refuid"];

  return (
    <div className="listGridItemOutter">
      <div className="listGridItemContent">
        <div className="listGridItem">
          <div className={""}>
            {/* <img
                    src={avatarImage}
                    alt="Avatar Image"
                    className="avatarLogoImage"
                />
                 */}
            <div className="" id={`care_Recipient_${UID}`}>
              <div className="row gx-3">
                <div className="col-auto">
                  <UserProfilePhoto
                    uid={UID}
                    relatedToType={CareEsioEntity.Employee.EntityName}
                    canEdit={false}
                    profileImgCss={"profileImg-container-normal"}
                    progressDivID={`#care_Recipient_${UID}`}
                    title={carePlanTitle ?? "Not Set"}
                    iconSize="40"
                    fontSize="16"
                    emptyAvatarType="Initial"
                  />
                </div>
                <div className="col ps-1 overflow-auto">
                  <div className="subtitle_1">
                    <span className="subtitle_1 ecllipseFirstLine text-wrap word-break-all semi_bold">
                      {carePlanTitle}
                    </span>
                  </div>
                  {isNotNullAndUndefined(dOBDate) ? (
                    <div className="d-flex">
                      <div className="body_2_dark ecllipseFirstLine text-wrap word-break-all">
                        {age}
                      </div>
                      <div className="mx-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="3"
                          height="4"
                          viewBox="0 0 3 4"
                          fill="none"
                        >
                          <circle cx="1.5" cy="2" r="1.5" fill="#A3A3A3" />
                        </svg>
                      </div>
                      <div className="body_2_dark ecllipseFirstLine text-wrap word-break-all">
                        {getDate(dOBDate, MomentFormats.DATE)}
                      </div>
                    </div>
                  ) : (
                    <div className="body_2_dark">Not set</div>
                  )}
                </div>
                {isNotNullAndUndefined(integration_refuid) ? (
                  <>
                    <div className="col-auto mt-1 pe-0">
                      <img src={xeroImgSrc} width={16} height={16} />
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="col-auto noRowclick">
                  <DropDownButtonComponent
                    items={[
                      { id: "Edit", text: "Edit" },
                      { id: "Delete", text: "Delete" },
                    ]}
                    cssClass="bg-transparent border-0 e-caret-hide"
                    select={(selectedMenu) => {
                      if (onActionMenuClicked) {
                        onActionMenuClicked(data, selectedMenu);
                      }
                    }}
                    iconCss={RAFButtonConstant.More.IconCss}
                  ></DropDownButtonComponent>
                </div>
              </div>
            </div>
            <div className="mt-3 custom__card__separator custom__card__separator__light"></div>

            <div className="py-2 d-flex align-items-center">
              <div
                style={{ width: "1rem", height: "1rem" }}
                className="d-flex align-items-center"
              >
                <i
                  className={`content_brand_secondary_base ${
                    isNotNullAndUndefined(currectGenderConstant)
                      ? currectGenderConstant.iconCss_l
                      : RAFGenderConstant.male.iconCss_l
                  }`}
                ></i>
              </div>
              <span className="ps-2 body_2_dark">
                <RAFDetailFieldCustom
                  value={CurrentGender}
                  title="Gender"
                  valueDivClassName="col-auto"
                  valueClassName="body_2_dark"
                  rowClassName="gx-1 align-items-baseline flex-nowrap"
                  field="gender"
                  moduleName={CareEsioEntity.Employee.EntityName}
                  showLabel={false}
                  fieldFormat={{ type: RAFDataType.Dropdown }}
                />
              </span>
            </div>
            <div className="custom__card__separator custom__card__separator__light"></div>
            <div className="py-2 d-flex align-items-center">
              <i
                className="fal fa-phone content_brand_secondary_base"
                style={{ width: "1rem", height: "1rem" }}
              ></i>
              <span className="ps-2 body_2_dark">
                {IsNotNullOrWhiteSpace(PrimaryPhone) ? PrimaryPhone : "Not set"}
              </span>
            </div>
            <div className="custom__card__separator custom__card__separator__light"></div>
            <div className="pt-2 d-flex align-items-center">
              <i
                className="fasl fa-location-dot content_brand_secondary_base"
                style={{ width: "1rem", height: "1rem" }}
              ></i>
              <div className="px-2 body_2_dark w-100">
                {IsNullOrWhiteSpace(city) && IsNullOrWhiteSpace(PostCode)
                  ? "Not set"
                  : `${city ?? ""}${
                      IsNotNullOrWhiteSpace(city) &&
                      IsNotNullOrWhiteSpace(PostCode)
                        ? `, `
                        : ""
                    }${PostCode ?? ""}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const employeeTableRowTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const UID = data["UID"];
  const firstName =
    isNotNullAndUndefined(data["first_name"]) ||
    isNotNullAndUndefined(data["middle_name"]) ||
    isNotNullAndUndefined(data["last_name"])
      ? data["first_name"]
      : "Not set";
  const middleName = data["middle_name"];
  const lastName = data["last_name"];
  const carePlanTitle = joinStringArray([firstName, middleName, lastName]);
  return (
    <div className="" id={`care_Recipient_${UID}`}>
      <div className="row gx-3 flex-nowrap align-items-center">
        <div className="col-auto">
          <UserProfilePhoto
            uid={UID}
            relatedToType={CareEsioEntity.Employee.EntityName}
            canEdit={false}
            profileImgCss={"profileImg-container-xsm"}
            progressDivID={`#care_Recipient_${UID}`}
            title={fieldValue ?? "Not Set"}
            iconSize="24"
            fontSize="10"
            emptyAvatarType="Initial"
          />
        </div>
        <div className="col ps-1">
          <div className="body_1_dark">
            <span className="body_1_dark ecllipseSecondLine">
              {fieldValue ?? "Not Set"}
            </span>
          </div>
        </div>
        <div className="col-auto noRowclick">
          <DropDownButtonComponent
            items={[
              { id: "Edit", text: "Edit" },
              { id: "Delete", text: "Delete" },
            ]}
            cssClass="bg-transparent border-0 e-caret-hide"
            select={(selectedMenu) => {
              if (onActionMenuClicked) {
                onActionMenuClicked(data, selectedMenu);
              }
            }}
            iconCss={RAFButtonConstant.More.IconCss}
          ></DropDownButtonComponent>
        </div>
      </div>
    </div>
  );
};

export const employeeIntegrationRefTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const UID = data["UID"];

  return (
    <div className="" id={`care_Recipient_${fieldName}_${UID}`}>
      {isNotNullAndUndefined(fieldValue) ? "Yes" : "No"}
    </div>
  );
};

export const complainceTableRowTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const UID = data["UID"];
  const title = data["Title"];
  const relatedToType = data["RelatedToType"];
  let permissionName;
  if (relatedToType === CareEsioEntity.CareRecipient.EntityName) {
    permissionName =
      CareRecipientPermissionConstants.CareRecipientManageCompliance;
  } else if (relatedToType === CareEsioEntity.Employee.EntityName) {
    permissionName =
      CareEmployeePermissionConstants.EmployeeUploadComplianceDocument;
  }

  return (
    <div className="" id={`checklist_item_trans_${UID}`}>
      <div className="row gx-1 flex-nowrap align-items-center">
        {/* <div className="col-auto">
          <UserProfilePhoto
            uid={UID}
            relatedToType={CareEsioEntity.ChecklistItemTrans.EntityName}
            canEdit={false}
            profileImgCss={"profileImg-container-xsm"}
            progressDivID={`#checklist_item_trans_${UID}`}
            title={title ?? "Not Set"}
            iconSize="24"
            fontSize="10"
            emptyAvatarType="Initial"
          />
        </div> */}
        <div className="col overflow-auto">
          <div className="body_1_dark">
            <span className="body_1_dark ecllipseFirstLine word-break-all text-wrap">
              {title}
            </span>
          </div>
        </div>
        <RAFPermissionRender permissionName={permissionName}>
          <div className="col-auto noRowclick">
            <DropDownButtonComponent
              items={[
                //{ id: "Edit", text: "Edit" },
                { id: "Delete", text: "Delete" },
              ]}
              cssClass="bg-transparent border-0 e-caret-hide"
              select={(selectedMenu) => {
                if (onActionMenuClicked) {
                  onActionMenuClicked(data, selectedMenu);
                }
              }}
              iconCss={RAFButtonConstant.More.IconCss}
            ></DropDownButtonComponent>
          </div>
        </RAFPermissionRender>
      </div>
    </div>
  );
};

const complainceListRowTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const UID = data["UID"];
  const title = data["Title"];

  const checklistItemTransItem = data;
  if (isNotNullAndUndefined(checklistItemTransItem)) {
    const status = checklistItemTransItem.DocumentStatus;

    const colorCodeName = isNotNullAndUndefined(status)
      ? RAFStatusNameWithColor[status]
      : null;

    let userUploadDisplayText = "Admin Upload";
    let userUploadDisplayStatus = "raf_badge_success";

    const checklistItemTransheaderContent = (checklistItemTransItem) => {
      return (
        <div className="row gx-2 align-items-center">
          {isNotNullAndUndefined(colorCodeName) && (
            <div className="col-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle
                  cx="4"
                  cy="4"
                  r="4"
                  fill={
                    isNotNullAndUndefined(colorCodeName)
                      ? hexToRGBA(colorCodeName.Color, 0.4)
                      : ""
                  }
                />
                <circle
                  cx="4"
                  cy="4"
                  r="2"
                  fill={
                    isNotNullAndUndefined(colorCodeName)
                      ? colorCodeName.Color
                      : ""
                  }
                />
              </svg>
            </div>
          )}
          <div className="col-auto body_3">
            <span
              className="body_3"
              style={{
                color: isNotNullAndUndefined(colorCodeName)
                  ? colorCodeName.Color
                  : "",
              }}
            >
              {isNotNullAndUndefined(colorCodeName)
                ? colorCodeName.DisplayName
                : isNotNullAndUndefined(status)
                ? status
                : "NA"}
            </span>
          </div>
        </div>
      );
    };

    const checklistItemTransBadgeContent = (checklistItemTransItem) => {
      let [statusMessage, statusClassName, formatedDate, showFormatedDate] =
        statesMessageContent(checklistItemTransItem.ExpiryDate);

      return (
        <RAFDetailsValueWithSeparator
          outerClassName="line-seprator h-auto"
          rowClassName="g-2"
        >
          <RAFDetailFieldCustom
            value={status}
            displayValue={status}
            title="DocumentStatus"
            moduleName={CareEsioEntity.ChecklistItemTrans.EntityName}
            isColorOption
            mode="ringView"
            field="DocumentStatus"
            showLabel={false}
          />
          {/* <div className="row gx-2 align-items-center">
        {isNotNullAndUndefined(colorCodeName) && 
          <div className="col-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
              <circle cx="4" cy="4" r="4" fill={isNotNullAndUndefined(colorCodeName) ? hexToRGBA(colorCodeName.Color, 0.4) : ''} />
              <circle cx="4" cy="4" r="2" fill={isNotNullAndUndefined(colorCodeName) ? colorCodeName.Color : ''} />
            </svg>
          </div>
    }
          <div className="col-auto body_3">
            <span className='body_3' style={{ color: isNotNullAndUndefined(colorCodeName) ? colorCodeName.Color : '' }}>
              {isNotNullAndUndefined(colorCodeName)
                ? colorCodeName.DisplayName
                : isNotNullAndUndefined(status)
                  ? status
                  : "NA"}
            </span>
          </div>
        </div> */}
          <div className="row gx-2">
            <div className="col-auto">
              <div className="body_3_light">Expiry date:</div>
            </div>
            <div className="col-auto">
              <div className="body_3 medium">
                {isNotNullAndUndefined(formatedDate) && (
                  <span className="content_brand_primary_base">
                    {formatedDate}
                  </span>
                )}
                {isNotNullAndUndefined(statusMessage) && (
                  <span className={`${statusClassName} ps-1`}>
                    {statusMessage}
                  </span>
                )}
              </div>
            </div>
          </div>
          {checklistItemTransItem.IsRequired && (
            <div className="raf_badge raf_xsm raf_badge_danger">Required</div>
          )}
          {checklistItemTransItem.IsCompliance && (
            <div className="raf_badge raf_xsm raf_badge_primary">
              Compliance
            </div>
          )}
          {/* {!checklistItemTransItem.AllowUserUpload && IsSuperAdmin() &&
            <div className={`raf_badge raf_xsm ${userUploadDisplayStatus}`}>
              {userUploadDisplayText}
            </div>
          } */}
        </RAFDetailsValueWithSeparator>
      );
    };

    return (
      <div className="listGridItemOutter" id={`checklist_item_trans_${UID}`}>
        <div className="listGridItemContent">
          <div className="listGridItem p-0">
            <div className="p-2-5">
              <div className="row gx-0 gy-1">
                {/* <div className="col-12">
                {checklistItemTransheaderContent(checklistItemTransItem)}
              </div> */}
                <div className="col-12">
                  <div className="body_3">
                    <span className="body_3_light">
                      {`${getEntityDisplayNameByModuleName(
                        checklistItemTransItem.RelatedToType
                      )} : `}
                    </span>
                    <span className="medium">
                      {checklistItemTransItem.RelatedTo}
                    </span>
                  </div>
                  <div className="subtitle_2 semi_bold mt-1">
                    <span>{checklistItemTransItem.Title}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__secondary__footer justify-content-between px-3 h-auto">
              {checklistItemTransBadgeContent(checklistItemTransItem)}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const expiryDateTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const checklistItemTransItem = data;
  if (isNotNullAndUndefined(checklistItemTransItem)) {
    const checklistItemTransBadgeContent = (checklistItemTransItem) => {
      let [statusMessage, statusClassName, formatedDate, showFormatedDate] =
        statesMessageContent(checklistItemTransItem.ExpiryDate);

      return (
        <div className="row gx-2">
          <div className="col-auto">
            <div className="body_3 medium tableValueWithHeight">
              {isNotNullAndUndefined(formatedDate) && (
                <span className="content_brand_primary_base">
                  {formatedDate}
                </span>
              )}
              {isNotNullAndUndefined(statusMessage) && (
                <span className={`${statusClassName} ps-1`}>
                  {statusMessage}
                </span>
              )}
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="gridDefault d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {checklistItemTransBadgeContent(checklistItemTransItem)}
        </div>
      </div>
    );
  }
};

const TeamNameTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const UID = data["UID"];

  let initial = fieldValue && fieldValue.charAt(0).toUpperCase();

  return (
    <div className="listGridItemOutter">
      <div className="listGridItemContent">
        <div className="listGridItem py-2">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-flex align-items-center w-100">
                <span
                  className={
                    fieldValue !== null && fieldValue !== undefined
                      ? "avatar-text-square default-avatar"
                      : ""
                  }
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    maxWidth: "30px",
                    fontSize: "12px",
                    fontWeight: 600,
                    marginRight: "8px",
                  }}
                >
                  {initial}
                </span>
                <div className="ecllipseFirstLine text-wrap word-break-all">
                  <span title={fieldValue}>{fieldValue}</span>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <RAFPermissionRender
                permissionName={PermissionConstants.RoleUpdate}
              >
                <RAFButtonComponent
                  action={RAFButtonConstant.Edit}
                  className="link-button e-editbutton"
                  // onClick={() => rowClick(data)}
                ></RAFButtonComponent>
              </RAFPermissionRender>
            </div>
            {/* <div className='col-auto noRowclick'>
                        <RAFPermissionRender permissionName={PermissionConstants.RoleDelete}>
                            <DropDownButtonComponent
                                items={[
                                    { id: 'Delete', text: 'Delete', iconCss: 'fas fa-trash', disabled: data.PermissionGroupName === SuperAdministrator ? true : false },
                                ]} cssClass="e-flat default e-caret-hide bg-rgba-grey p-0"
                                select={onMoreMenuClicked.bind(this, data)}
                                iconCss="fas fa-ellipsis-vertical"
                            >
                            </DropDownButtonComponent>
                        </RAFPermissionRender>
                    </div> */}
          </div>
        </div>
      </div>
    </div>
  );
  // }
  // return <div> {"\u00A0"}</div>;
};

export const dueDateTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldValue = data[data.column.field];
  const modifiedBy = data["ModifiedBy"];
  if (isNotNullAndUndefined(fieldValue)) {
    return <>moment(fieldValue).subtract(20, "s").fromNow()</>;
  }
  return;
};

export const emailDateTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldValue = data[data.column.field];
  const modifiedBy = data["ModifiedBy"];
  if (isNotNullAndUndefined(fieldValue)) {
    let emailDate = moment(new Date(fieldValue))
      .format("ddd MM/DD hh:mm a")
      .toString();
    let emailDateAndModifiedByTooltip = moment(new Date(fieldValue))
      .format("DD/MM/YYYY hh:mm a")
      .toString();

    if (isNotNullAndUndefined(modifiedBy)) {
      emailDateAndModifiedByTooltip =
        "<div style='font-size: 0.875rem'><div class='pb-2'><b>Date</b>: " +
        moment(new Date(fieldValue)).format("DD/MM/YYYY hh:mm a").toString() +
        "</div><b>Modified By</b>: " +
        modifiedBy +
        "</div>";
    }

    return (
      <div className="gridDefault d-flex align-items-center justify-content-between removeTooltip noRowclick">
        <div className="d-flex align-items-center">
          <TooltipComponent
            content={emailDateAndModifiedByTooltip}
            position="BottomCenter"
          >
            <span className="mail-date-style">{emailDate}</span>
          </TooltipComponent>
        </div>
      </div>
    );
  }
  return;
};

export const emailRelatedToTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  //const fieldName = data.column.field;
  //const fieldValue = data[fieldName];
  let iconName = "";
  let text = "";
  //let className = '';
  //let url = '';
  let avatarClassName = "";

  switch (data["RelatedToType"]) {
    case "contact":
      iconName = "address-card";
      text = "Contact";
      //className = 'contact-badge';
      avatarClassName = "contact-avatar";
      //url = '/Contact/View/';
      break;
    case "ticket":
      iconName = "ticket-alt";
      text = "Ticket";
      //className = 'ticket-badge';
      avatarClassName = "ticket-avatar";
      //url = '/Ticket/View/';
      break;
    default:
      break;
  }

  const relatedToUID = data["RelatedToUID"];
  return (
    <div className="swapTextwithHyperlink gridDefault text-wrap d-flex align-items-center justify-content-between">
      <div
        className="emailRelatedToTemplate relatedTo"
        onClick={() =>
          onLookupLinkClicked(
            data["RelatedToType"],
            relatedToUID,
            isDynamic,
            navigate
          )
        }
      >
        <div
          className="d-flex align-items-center"
          style={{ marginBottom: "2px" }}
        >
          <TooltipComponent content={text} position="BottomCenter">
            {!Browser.isDevice && iconName !== "" ? (
              <span
                className={
                  "avatar-text-circle transparent-avatar " + avatarClassName
                }
                style={{
                  width: "24px",
                  height: "24px",
                  minWidth: "24px",
                  maxWidth: "24px",
                  fontSize: "14px",
                }}
              >
                <i className={"fa fa-" + iconName + " "}></i>
              </span>
            ) : (
              <span
                className={
                  "avatar-text-circle default-avatar transparent-avatar"
                }
                style={{
                  background: "#bdbdbd",
                  width: "24px",
                  height: "24px",
                  minWidth: "24px",
                  maxWidth: "24px",
                  marginRight: "5px",
                  fontSize: "12px",
                }}
              >
                <span>N</span>
              </span>
            )}
          </TooltipComponent>
          {isNotNullAndUndefined(data["RelatedTo"]) ? (
            <div className="e-custombutton">
              <span className="blue-text ecllipseFirstLine word-break-all">
                {data["RelatedTo"]}
              </span>
            </div>
          ) : (
            <div className="e-editbutton">
              <span className="blue-text ecllipseFirstLine">Not set</span>
            </div>
          )}
        </div>
      </div>
      <div className="edit_btn gridEditBtn">
        <ButtonComponent
          type="button"
          className="btndefault-icon e-editbutton custom-button-sm"
          iconCss="fal fa-pencil"
        />
      </div>
    </div>
  );
};

export const wsTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const iconName: IconList = data["Icon"];
  if (isNotNullAndUndefined(fieldValue)) {
    return (
      <div className="gridDefault d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center noRowclick w-100">
          <div className="bp-template-item">
            {isEmptyOrNull(iconName) ? (
              <RAFIconImage
                iconCssClass={"fa fa-group-arrows-rotate"}
                moduleavatar="bp-avatar hover-hide-td-child-div-flex"
                iconSize="30"
                fontSize="16"
              ></RAFIconImage>
            ) : (
              <div>
                <span
                  className="bp-avatar avatar-text hover-hide-td-child-div-flex"
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    maxWidth: "30px",
                    fontSize: "16px",
                  }}
                >
                  <IconPickerItem icon={iconName} size={16} />
                </span>
              </div>
            )}
            <div className="d-flex align-items-center">
              <RAFIconImage
                iconCssClass={"fal fa-pencil"}
                moduleavatar="bp-avatar avatar-text-circle e-editbutton hover-show-td-child-div-flex"
                iconSize="30"
                fontSize="16"
              ></RAFIconImage>
            </div>
            <div className="px-md-3" style={{ width: "calc(100% - 30px)" }}>
              <span className="text-wrap header-text">{fieldValue}</span>
            </div>
          </div>
        </div>
        <div
          className="d-flex"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <RAFRecordInfo
            createdBy={data["CreatedBy"]}
            content=""
            createdDate={data["CreatedDate"]}
            modifiedDate={data["ModifiedDate"]}
            modifiedBy={data["ModifiedBy"]}
            lastActivityDate={data["LastActivityDate"]}
          ></RAFRecordInfo>
        </div>
      </div>
    );
  }
  return;
};

export const workFlowStatus = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldValue = data[data.column.field];
  if (isNotNullAndUndefined(fieldValue)) {
    let fieldvalue;
    if (fieldValue === 1) {
      fieldvalue = "Trigger";
    } else if (fieldValue === 2) {
      fieldvalue = "Done";
    }
    return (
      <div className="gridDefault d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <span className="">{fieldvalue}</span>
        </div>
        {showEdit === true && (
          <div className="gridEditBtn">
            <ButtonComponent
              type="button"
              className="e-flat btndefault-icon e-editbutton"
              iconCss="fal fa-pencil"
            />
          </div>
        )}
      </div>
    );
  }
  return;
};

export const processTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const processUID = data["UID"];
  const stepCount = data["StepCount"];
  const iconName: IconList = data["IconName"];
  const completedCount = data["CompletedCount"];
  const VersionNumber = data["VersionNumber"];
  let totalSteps =
    isNotNullAndUndefined(stepCount) && stepCount > 0 ? stepCount : 0;
  let completedSteps =
    isNotNullAndUndefined(completedCount) && completedCount > 0
      ? completedCount
      : 0;
  let bpStepRow = [];
  for (let i = 0; i < totalSteps; i++) {
    if (i < completedSteps) {
      bpStepRow.push(<li key={i} className="bp-step-li completed"></li>);
    } else {
      bpStepRow.push(<li key={i} className="bp-step-li"></li>);
    }
  }

  // let versionNumber = isNotNullAndUndefined(VersionNumber)
  //   ? " (v" + VersionNumber + ")"
  //   : "";

  if (isNotNullAndUndefined(fieldValue)) {
    return (
      <div className="gridDefault d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center w-100">
          {/* <div className="bp-template-item hidden">
                {isEmptyOrNull(iconName) ? (
                  <RAFIconImage
                    iconCssClass={"fa fa-group-arrows-rotate"}
                    moduleavatar="bp-avatar transparent-avatar dashed-avatar"
                    iconSize="30"
                    fontSize="16"
                  ></RAFIconImage>
                ) : (
                  <div>
                    <span
                      className="bp-avatar avatar-text transparent-avatar dashed-avatar"
                      style={{
                        width: "30px",
                        height: "30px",
                        minWidth: "30px",
                        maxWidth: "30px",
                        fontSize: "16px",
                      }}
                    >
                      <IconPickerItem
                        icon={iconName}
                        size={16}
                        color={"#0070d2"}
                      />
                    </span>
                  </div>
                )}
                <div style={{ width: "calc(100% - 30px)" }}>
                  <span className="bp-template-title">
                    {fieldValue + versionNumber}
                  </span>
                  {totalSteps > 0 && (
                    <div className="d-flex align-items-center">
                      <ul className="bp-step-ul" id={"bpUlRow_" + processUID}>
                        {bpStepRow}
                      </ul>
                      <span className="bp-template-content">
                        {totalSteps + " step(s)"}
                      </span>
                    </div>
                  )}
                </div>
              </div> */}
          <div className="bp-template-item">
            {showEdit === true && allowEditing === true ? (
              <div>
                <span
                  className={
                    fieldValue !== null && fieldValue !== undefined
                      ? " hover-hide-td-child-div-flex"
                      : "hover-hide-td-child-div-flex"
                  }
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    maxWidth: "30px",
                    fontSize: "12px",
                    fontWeight: 600,
                    marginRight: "8px",
                  }}
                >
                  {isEmptyOrNull(iconName) ? (
                    <RAFIconImage
                      iconCssClass={"fa fa-group-arrows-rotate"}
                      moduleavatar="bp-avatar"
                      iconSize="30"
                      fontSize="16"
                    ></RAFIconImage>
                  ) : (
                    <div>
                      <span
                        className="bp-avatar avatar-text"
                        style={{
                          width: "30px",
                          height: "30px",
                          minWidth: "30px",
                          maxWidth: "30px",
                          fontSize: "16px",
                        }}
                      >
                        <IconPickerItem icon={iconName} size={16} />
                      </span>
                    </div>
                  )}
                </span>
                <span
                  className={
                    fieldValue !== null && fieldValue !== undefined
                      ? "bp-avatar avatar-text e-editbutton hover-show-td-child-div-flex"
                      : "e-editbutton hover-show-td-child-div-flex"
                  }
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    maxWidth: "30px",
                    fontSize: "12px",
                    fontWeight: 600,
                    marginRight: "8px",
                  }}
                >
                  <span
                    className="fal fa-pencil"
                    style={{ fontSize: "12px" }}
                  ></span>
                </span>
              </div>
            ) : (
              <span
                className={
                  fieldValue !== null && fieldValue !== undefined ? "" : ""
                }
                style={{
                  width: "30px",
                  height: "30px",
                  minWidth: "30px",
                  maxWidth: "30px",
                  fontSize: "12px",
                  fontWeight: 600,
                  marginRight: "8px",
                }}
              >
                {isEmptyOrNull(iconName) ? (
                  <RAFIconImage
                    iconCssClass={"fa fa-group-arrows-rotate"}
                    moduleavatar="bp-avatar"
                    iconSize="30"
                    fontSize="16"
                  ></RAFIconImage>
                ) : (
                  <div>
                    <span
                      className="bp-avatar avatar-text"
                      style={{
                        width: "30px",
                        height: "30px",
                        minWidth: "30px",
                        maxWidth: "30px",
                        fontSize: "16px",
                      }}
                    >
                      <IconPickerItem icon={iconName} size={16} />
                    </span>
                  </div>
                )}
              </span>
            )}
            <div className="w-100 overflow-auto">
              <div className="d-flex flex-nowrap">
                <div className="w-100 overflow-auto">
                  <span
                    className="bp-template-title e-FirstLine-mention-outer"
                    //{fieldValue + versionNumber}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(`${fieldValue}`),
                    }}
                  ></span>
                </div>
                <div className="ps-1">
                  {isNotNullAndUndefined(VersionNumber) && (
                    <span className="bp-template-title">
                      {` v(${VersionNumber})`}
                    </span>
                  )}
                </div>
              </div>
              {totalSteps > 0 && (
                <div className="d-flex align-items-center">
                  <ul className="bp-step-ul" id={"bpUlRow_" + processUID}>
                    {bpStepRow}
                  </ul>
                  <span className="bp-template-content">
                    {totalSteps + " step(s)"}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return;
};

export const progressBarTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const stepCount = data["StepCount"];
  const completedCount = data["CompletedCount"];
  let totalSteps =
    isNotNullAndUndefined(stepCount) && stepCount > 0 ? stepCount : 0;
  let completedSteps =
    isNotNullAndUndefined(completedCount) && completedCount > 0
      ? completedCount
      : 0;
  let bpStepRow = [];
  for (let i = 0; i < totalSteps; i++) {
    if (i < completedSteps) {
      bpStepRow.push(<li key={i} className="bp-step-li completed"></li>);
    } else {
      bpStepRow.push(<li key={i} className="bp-step-li"></li>);
    }
  }

  if (isNotNullAndUndefined(fieldValue)) {
    return (
      <RAFBPProgressBar
        totalSteps={totalSteps}
        completedSteps={completedSteps}
        hideProgressValue
      />
    );
  }
  return;
};

export const bpTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const stepCount = data["StepCount"];
  //const initiatorUID = data['InitiatorUID'];
  const iconName: IconList = data["IconName"];
  //const loggedinUserId = msalInstance.currentUserId;
  let totalSteps =
    isNotNullAndUndefined(stepCount) && stepCount > 0 ? stepCount : 0;
  //let formUIDValue = data['FormUID'];
  if (isNotNullAndUndefined(fieldValue)) {
    return (
      <div className="gridDefault d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center noRowclick w-100">
          <div className="bp-template-item">
            {isEmptyOrNull(iconName) ? (
              <RAFIconImage
                iconCssClass={"fa fa-group-arrows-rotate"}
                //moduleavatar="bp-avatar hover-hide-td-child-div-flex"
                moduleavatar="bp-avatar"
                iconSize="30"
                fontSize="16"
              ></RAFIconImage>
            ) : (
              <div>
                <span
                  //className="bp-avatar avatar-text hover-hide-td-child-div-flex"
                  className="bp-avatar avatar-text"
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    maxWidth: "30px",
                    fontSize: "16px",
                  }}
                >
                  <IconPickerItem icon={iconName} size={16} />
                </span>
              </div>
            )}
            {/*{showEdit === true ?
                              <div className="d-flex align-items-center">
                                  <RAFIconImage iconCssClass={"e-icons e-edit"} moduleavatar="bp-avatar avatar-text-circle e-editbutton hover-show-td-child-div-flex" iconSize="30" fontSize="12"></RAFIconImage>
                              </div>
                              :
                              isEmptyOrNull(iconName) ?
                                  <RAFIconImage iconCssClass={"fa fa-group-arrows-rotate"} moduleavatar="bp-avatar hover-show-td-child-div-flex" iconSize="30" fontSize="12"></RAFIconImage>
                                  :
                                  <div>
                                      <span className="bp-avatar avatar-text hover-show-td-child-div-flex" style={{ width: "30px", height: "30px", minWidth: "30px", maxWidth: "30px", fontSize: "12px" }}>
                                          <IconPickerItem icon={iconName} size={12} />
                                      </span>
                                  </div>
  
                          }*/}
            <div className="px-3" style={{ width: "calc(100% - 30px)" }}>
              <span className="bp-template-title ecllipseFirstLine text-wrap word-break-all">
                {fieldValue}
              </span>
              {totalSteps > 0 && (
                <div className="d-flex align-items-center">
                  <span className="bp-template-content">
                    {totalSteps + " step(s)"}
                  </span>
                </div>
              )}
            </div>
            <div className="row hover-show-td-child-div-flex flex-nowrap g-2">
              <div className="col-auto hidden">
                <ButtonComponent
                  type="button"
                  className="e-custom-actionbutton primary-custom-button e-small square-btn m-0"
                  iconCss="fa fa-list-alt"
                ></ButtonComponent>
              </div>
              <div className="col-auto">
                <ButtonComponent
                  type="button"
                  className="e-custombutton primary-custom-button e-md square-btn m-0"
                  iconCss="fas fa-play-circle"
                ></ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return;
};

export const bpRowTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const descriptionValue = data["Description"];
  const executionOrderValue = data["ExecutionOrder"];
  const formNameValue = data["FormName"];
  const stepCount = data["StepCount"];
  const iconName: IconList = data["IconName"];
  const formType = data["FormType"];
  const processStatus = data["CurrentStatus"];
  const isActive = data["IsActive"];
  const rRuleExpression = data["RRuleExpression"];
  const versionNumber = data["VersionNumber"];

  const createdBy = data["CreatedBy"];
  const createdDate = data["CreatedDate"];
  const modifiedDate = data["ModifiedDate"];
  const modifiedBy = data["ModifiedBy"];
  const lastActivityDate = data["LastActivityDate"];
  let processStatusList =
    item &&
    item.ValueJson &&
    item.ValueJson.find((x) => x.DisplayName === processStatus);
  let processStatusColorCode = processStatusList && processStatusList.ColorCode;
  let totalSteps =
    isNotNullAndUndefined(stepCount) && stepCount > 0 ? stepCount : 0;

  let currentStatusClassName;
  if (processStatus === RAFBPCurrentStatus.Published) {
    currentStatusClassName = "e-badge-success";
  } else if (processStatus === RAFBPCurrentStatus.Draft) {
    currentStatusClassName = "e-badge-primary";
  } else if (processStatus === RAFBPCurrentStatus.Archived) {
    currentStatusClassName = "e-badge-dark";
  }

  if (isNotNullAndUndefined(fieldValue)) {
    return (
      <div className="listGridItemOutter">
        <div className="listGridItemContent">
          <div className="listGridItem">
            <div className="gridDefault bp-template-item align-items-center">
              {isEmptyOrNull(iconName) ? (
                <div className="">
                  <RAFIconImage
                    iconCssClass={"fa fa-group-arrows-rotate"}
                    moduleavatar="grey-avatar avatar-text-square avatar-text transparent-avatar dashed-avatar"
                    iconSize="38"
                    fontSize="28"
                  ></RAFIconImage>
                </div>
              ) : (
                <div className="">
                  <span
                    className="grey-avatar avatar-text-square avatar-text transparent-avatar dashed-avatar"
                    style={{
                      width: "38px",
                      height: "38px",
                      minWidth: "38px",
                      maxWidth: "38px",
                      fontSize: "28px",
                    }}
                  >
                    <IconPickerItem icon={iconName} size={28} />
                  </span>
                </div>
              )}
              <div className="px-2 ms-1" style={{ width: "calc(100% - 30px)" }}>
                <div className="row gy-2 gx-0">
                  <div className="col-12">
                    <span className="bp-template-title ecllipseFirstLine text-wrap">
                      {`${fieldValue} (v${versionNumber})`}
                    </span>
                  </div>
                  {/* {isNotNullAndUndefined(descriptionValue) ? (
                  <div className="col-12">
                    <span className="bp-template-secondary-title ecllipseFirstLine text-wrap">
                      {descriptionValue}
                    </span>
                  </div>
                ) : null} */}
                  <div className="col-12">
                    <div className="detailsLeftCard-second-title h-auto">
                      <div className="row gx-2 align-items-center">
                        {isNotNullAndUndefined(processStatus) ? (
                          <div className="col-auto detailsLeftCard-second-title-col">
                            <div className="detailsLeftCard-second-title-item">
                              <span
                                className={`e-badge ${currentStatusClassName}`}
                              >
                                {processStatus}
                              </span>
                            </div>
                          </div>
                        ) : null}
                        <div className="col-auto detailsLeftCard-second-title-col">
                          <div className="detailsLeftCard-second-title-item">
                            {totalSteps + " step(s)"}
                          </div>
                        </div>
                        <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                          <div className="detailsLeftCard-second-title-item d-flex align-items-center">
                            <div className="ecllipseFirstLine">
                              <RAFRecordInfo
                                createdBy={createdBy}
                                createdDate={createdDate}
                                modifiedDate={modifiedDate}
                                modifiedBy={modifiedBy}
                                lastActivityDate={lastActivityDate}
                                dropdownBtnClassName="unset-custom-button-md"
                                hideClockIcon
                              ></RAFRecordInfo>
                            </div>
                          </div>
                        </div>
                        {/* {isNotNullAndUndefined(formNameValue) ? (
                            <div className="col-auto detailsLeftCard-second-title-col">
                              <div className="detailsLeftCard-second-title-item">
                                {formNameValue}
                              </div>
                            </div>
                          ) : null} */}
                        {isNotNullAndUndefined(rRuleExpression) && (
                          <div className="col-auto detailsLeftCard-second-title-col">
                            <div className="detailsLeftCard-second-title-item">
                              {RRule.fromString(
                                rRuleExpression.slice(
                                  0,
                                  rRuleExpression.length - 1
                                )
                              ).toText()}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="w-100">
                      <div
                        className="row align-items-center gx-1"
                        style={{ lineHeight: "normal" }}
                      >
                        {totalSteps > 0 && (
                          <div className="col-auto">
                            <span className="bp-template-content">
                              {totalSteps + " step(s)"}
                            </span>
                          </div>
                        )}
                        {totalSteps > 0 &&
                          formType !== "Entity" &&
                          (isNotNullAndUndefined(formNameValue) ||
                            isNotNullAndUndefined(executionOrderValue)) ? (
                          <div className="col-auto separator-dot">
                            <i className="fas fa-circle"></i>
                          </div>
                        ) : null}
                        {formType !== "Entity" &&
                          isNotNullAndUndefined(formNameValue) ? (
                          <div className="col-auto">
                            <span className="bp-template-content ecllipseFirstLine text-wrap">
                              {formNameValue}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="listGridItemFooter">
            <div className="row g-2 flex-nowrap align-items-center justify-content-end">
              <div className="col-auto">
                <div className="row g-2">
                  {processStatus === RAFBPCurrentStatus.Published &&
                    isActive === true && (
                      <div className="col-auto">
                        <ButtonComponent
                          type="button"
                          cssClass="custom-button-sm e-custombutton e-outline e-success"
                          content="Start"
                          iconCss="fas fa-play-circle"
                        ></ButtonComponent>
                      </div>
                    )}

                  <div className="col-auto d-none d-lg-block">
                    <ButtonComponent
                      type="button"
                      iconCss="fa-regular fa-line-columns"
                      cssClass="custom-button-sm e-outline e-custombutton_3"
                      content="Datalist"
                    ></ButtonComponent>
                  </div>
                  <div className="col-auto d-lg-none">
                    <ButtonComponent
                      type="button"
                      iconCss="fa-regular fa-line-columns"
                      cssClass="custom-button-sm e-outline e-custombutton_3 mt-0-5"
                    ></ButtonComponent>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <div className="row g-2">
                  <div className="col-auto d-none d-lg-block">
                    <ButtonComponent
                      type="button"
                      iconCss="fa-light fa-monitor-waveform"
                      cssClass="custom-button-sm e-outline e-custombutton_2"
                      content="Monitor Status"
                    ></ButtonComponent>
                  </div>
                  <div className="col-auto d-lg-none">
                    <ButtonComponent
                      type="button"
                      iconCss="fa-light fa-monitor-waveform"
                      cssClass="custom-button-sm e-outline e-custombutton_2 mt-0-5"
                    ></ButtonComponent>
                  </div>
                  <div className="col-auto">
                    <ButtonComponent
                      type="button"
                      cssClass="custom-button-sm e-outline e-custom-actionbutton"
                      // cssClass="link-button e-custom-actionbutton"
                      iconCss={RAFButtonConstant.Edit.IconCss}
                      content={"Designer"}
                    ></ButtonComponent>
                  </div>
                </div>
              </div>
              {/* <div className="col-auto">
                  <ButtonComponent
                    type="button"
                    cssClass="custom-button-sm e-outline e-custom-actionbutton"
                    // cssClass="link-button e-custom-actionbutton"
                    iconCss={`fas fa-${getModuleAvatarIcon(
                      RAFEntityName.BusinessProcess
                    )}`}
                    content={"Show Runs"}
                  ></ButtonComponent>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return;
};

export const formLibraryTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data
) => {
  const iconName: IconList = data["IconName"];
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const formType = data["FormType"];
  const pageType = data["PageType"];
  const category = data["Category"];
  const createdBy = data["CreatedBy"];
  const createdDate = data["CreatedDate"];
  const modifiedDate = data["ModifiedDate"];
  const modifiedBy = data["ModifiedBy"];
  const lastActivityDate = data["LastActivityDate"];
  const currentStatus = data["CurrentStatus"];
  return (
    <div className="listGridItemOutter">
      <div className="listGridItemContent">
        <div className="listGridItem">
          <div className="gridDefault bp-template-item align-items-center">
            {isEmptyOrNull(iconName) ? (
              <div className="">
                <RAFIconImage
                  iconCssClass={"fa fa-group-arrows-rotate"}
                  moduleavatar="grey-avatar avatar-text-square avatar-text transparent-avatar dashed-avatar"
                  iconSize="38"
                  fontSize="28"
                ></RAFIconImage>
              </div>
            ) : (
              <div className="">
                <span
                  className="grey-avatar avatar-text-square avatar-text transparent-avatar dashed-avatar"
                  style={{
                    width: "38px",
                    height: "38px",
                    minWidth: "38px",
                    maxWidth: "38px",
                    fontSize: "28px",
                  }}
                >
                  <IconPickerItem icon={iconName} size={28} />
                </span>
              </div>
            )}
            <div className="px-2" style={{ width: "calc(100% - 30px)" }}>
              <div className="row gx-0 gy-2">
                <div className="col-12">
                  <span className="bp-template-title ecllipseFirstLine text-wrap">
                    {`${fieldValue}`}
                  </span>
                </div>

                <div className="detailsLeftCard-second-title h-auto">
                  <div className="row gx-2 flex-nowrap align-items-center">
                    {isNotNullAndUndefined(pageType) ? (
                      <div className="col-auto detailsLeftCard-second-title-col">
                        <div className="detailsLeftCard-second-title-item">
                          <div className="">
                            <span className="e-badge e-badge-border px-2 p-1 text-nowrap">
                              {pageType}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {isNotNullAndUndefined(formType) ? (
                          <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                            <div className="detailsLeftCard-second-title-item">
                              <div className="ecllipseFirstLine">{formType}</div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )} */}
                    {isNotNullAndUndefined(currentStatus) ? (
                      <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                        <div className="detailsLeftCard-second-title-item">
                          <div className="ecllipseFirstLine">
                            {currentStatus}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {isNotNullAndUndefined(category) ? (
                      <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                        <div className="detailsLeftCard-second-title-item">
                          <div className="ecllipseFirstLine">{category}</div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {isNotNullAndUndefined(tags) && (
                                <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                                  <div className="detailsLeftCard-second-title-item">
                                    <div className="row g-1 tag-input flex-nowrap">
                                      {tags &&
                                        tags.map((tag, index) => (
                                          <div className="col-auto" key={index}>
                                            <span
                                              className="e-badge e-badge-notifiation"
                                              key={index}
                                            >
                                              {tag}
                                            </span>
                                          </div>
                                        ))}
                                    </div>
                                    {/* <span>{tags}</span> */}
                    {/*</div>
                                </div>
                              )} */}
                    <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                      <div className="detailsLeftCard-second-title-item">
                        <div className="ecllipseFirstLine noRowclick">
                          <RAFRecordInfo
                            createdBy={createdBy}
                            createdDate={createdDate}
                            modifiedDate={modifiedDate}
                            modifiedBy={modifiedBy}
                            lastActivityDate={lastActivityDate}
                            dropdownBtnClassName="unset-custom-button-md"
                            hideClockIcon
                          ></RAFRecordInfo>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {isNotNullAndUndefined(descriptionValue) ? (
                  <div className="col-12">
                    <span className="bp-template-secondary-title ecllipseFirstLine text-wrap">
                      {descriptionValue}
                    </span>
                  </div>
                ) : null} */}
              </div>
            </div>
            <div className="row g-2 flex-nowrap">
              {/* {formType === RAFFormType.UserForm && (
                  <>
                    <div className="col-auto">
                      <ButtonComponent
                        type="button"
                        cssClass="custom-button-sm e-outline e-custom-actionbutton d-none d-sm-block"
                        // cssClass="link-button e-custom-actionbutton"
  
                        content={"View Records"}
                      // iconCss={processStatus === RAFBPCurrentStatus.Published ? "fas fa-file-lines" : "fas fa-list-ul"}
                      ></ButtonComponent>
                    </div>
                    <div className="col-auto">
                      <ButtonComponent type="button"
                        cssClass="custom-button-sm e-outline e-custom-actionbutton d-sm-none"
                        // cssClass="link-button e-custom-actionbutton"
                        iconCss={"fas fa-list-ul"}
  
                      ></ButtonComponent>
                    </div>
                  </>
                )} */}
              {/* <div className="col-auto">
              <ButtonComponent
                type="button"
                cssClass="custom-button-sm e-custombutton e-outline"
                isPrimary
                content={"Designer"}
              // iconCss="fas fa-play-circle"
              ></ButtonComponent>
            </div> */}
            </div>
            {/* <div className="row g-2 flex-nowrap d-sm-none">
              {processStatus === RAFBPCurrentStatus.Published &&
                <div className="col-auto">
                  <ButtonComponent type="button" cssClass="custom-button-sm e-custombutton e-outline e-success"
                    iconCss="fas fa-play-circle"></ButtonComponent>
                </div>
              }
              <div className="col-auto">
                <ButtonComponent type="button"
                  cssClass="custom-button-sm e-outline e-custom-actionbutton"
                  // cssClass="link-button e-custom-actionbutton"
                  iconCss={processStatus === RAFBPCurrentStatus.Published ? "fas fa-file-lines" : "fas fa-list-ul"}
  
                ></ButtonComponent>
              </div>
            </div> */}
          </div>
        </div>
        <div className="listGridItemFooter">
          <div className="row g-2 flex-nowrap justify-content-between">
            <div className="col-auto">
              <ButtonComponent
                type="button"
                cssClass="custom-button-sm e-custombutton e-outline"
                content={"Designer"}
                // iconCss="fas fa-play-circle"
              ></ButtonComponent>
            </div>
            <div className="col-auto row g-1 flex-nowrap justify-content-between">
              {formType === RAFFormType.UserForm &&
                currentStatus === RAFBPCurrentStatus.Published && (
                  <div className="col-auto">
                    <ButtonComponent
                      type="button"
                      cssClass="custom-button-sm e-outline e-custombutton_2"
                      // cssClass="link-button e-custom-actionbutton"

                      content={"Add Record"}
                      // iconCss={processStatus === RAFBPCurrentStatus.Published ? "fas fa-file-lines" : "fas fa-list-ul"}
                    ></ButtonComponent>
                  </div>
                )}
              {formType === RAFFormType.UserForm && (
                <div className="col-auto">
                  <ButtonComponent
                    type="button"
                    cssClass="custom-button-sm e-outline e-custom-actionbutton"
                    // cssClass="link-button e-custom-actionbutton"

                    content={"Datalist"}
                    // iconCss={processStatus === RAFBPCurrentStatus.Published ? "fas fa-file-lines" : "fas fa-list-ul"}
                  ></ButtonComponent>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ViewTypeViewDisplayName = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  if (isNotNullAndUndefined(fieldValue)) {
    return (
      <div className="gridDefault d-flex align-items-center justify-content-between raf-account-relatedto">
        <div className="d-flex align-items-center w-100 overflow-auto">
          <span
            className="avatar-text-square view-avatar"
            style={{
              width: "26px",
              height: "26px",
              minWidth: "26px",
              maxWidth: "26px",
              marginRight: "5px",
              fontSize: "12px",
            }}
          >
            <span className="fa fa-clipboard-list"></span>
          </span>
          <div className="gridTempleteTextOuter">
            <span
              className="ms-2"
              style={{ color: "#0078d6", cursor: "pointer" }}
            >
              {fieldValue}
            </span>
          </div>
        </div>
        <div className="hover-td-child-div-flex">
          <ButtonComponent
            type="button"
            className="e-custom-actionbutton custom-button-sm link-button e-small m-0"
          >
            Clone
          </ButtonComponent>
          <ButtonComponent
            type="button"
            className="e-custombutton custom-button-sm link-button e-small mx-1"
          >
            Rename
          </ButtonComponent>
        </div>
      </div>
    );
  }
  return;
};

export const ViewTypeViewTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];

  let displayText =
    fieldValue && fieldValue.charAt(0).toUpperCase() + fieldValue.slice(1);

  if (isNotNullAndUndefined(displayText)) {
    return (
      <div className="gridDefault d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center w-100">
          <div className="gridTempleteTextOuter">
            <span className="ms-2" style={{ cursor: "pointer" }}>
              {displayText}
            </span>
          </div>
        </div>
      </div>
    );
  }
  return;
};

export const visibilityViewTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldVariable = data["SettingsJson"];
  const fieldValue = fieldVariable && fieldVariable.IsPrivate;

  let displayText = "Public";
  if (isNotNullAndUndefined(fieldValue) && fieldValue) {
    displayText = "Private";
  } else {
    displayText = "Public";
  }
  if (isNotNullAndUndefined(displayText)) {
    return (
      <div className="gridDefault d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center w-100">
          <div className="gridTempleteTextOuter">
            <span className="ms-2" style={{ cursor: "pointer" }}>
              {displayText}
            </span>
          </div>
        </div>
      </div>
    );
  }
  return;
};

export const showInListViewTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldVariable = data["SettingsJson"];
  const fieldValue = fieldVariable && fieldVariable.ShowInList;

  let displayText = "No";
  if (isNotNullAndUndefined(fieldValue) && fieldValue === true) {
    displayText = "Yes";
  } else {
    displayText = "No";
  }
  if (isNotNullAndUndefined(displayText)) {
    return (
      <div className="gridDefault d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center w-100">
          <div className="gridTempleteTextOuter">
            <span>{displayText}</span>
          </div>
        </div>
      </div>
    );
  }
  return;
};

export const formLibraryNameTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const category = data["FormType"];
  //let initial = fieldValue && fieldValue.charAt(0).toUpperCase();
  const iconName: IconList = data.IconName;
  if (isNotNullAndUndefined(fieldValue)) {
    return (
      <div className="gridDefault d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center noRowclick w-100">
          <div className="bp-template-item p-0 align-items-center">
            {isEmptyOrNull(iconName) ? (
              <div>
                <RAFIconImage
                  iconCssClass={"fa fa-file-invoice"}
                  //moduleavatar="bp-avatar hover-hide-td-child-div-flex"
                  moduleavatar="form-library-avatar"
                  iconSize="30"
                  fontSize="16"
                ></RAFIconImage>
              </div>
            ) : (
              <div>
                <span
                  //className="bp-avatar avatar-text hover-hide-td-child-div-flex"
                  className="form-library-avatar avatar-text"
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    maxWidth: "30px",
                    fontSize: "16px",
                  }}
                >
                  <IconPickerItem icon={iconName} size={16} />
                </span>
              </div>
            )}
            {/* <div>
                              <span
                                  className="form-library-avatar avatar-text"
                                  style={{ width: "30px", height: "30px", minWidth: "30px", maxWidth: "30px", fontSize: "12px" }}>
                                  {initial}
                              </span>
                          </div> */}
            <div className="px-2" style={{ width: "calc(100% - 30px)" }}>
              <span className="bp-template-title ecllipseFirstLine text-wrap word-break-all">
                {fieldValue}
              </span>
            </div>
            {category === RAFFormType.FormComponent && (
              <div className="row hover-show-td-child-div-flex flex-nowrap g-2">
                <div className="col-auto">
                  <ButtonComponent
                    type="button"
                    className="e-custom-actionbutton unset-custom-button-md link-button e-small m-0"
                  >
                    Form Editor
                  </ButtonComponent>
                </div>
              </div>
            )}
            {category === RAFFormType.UserForm && (
              <div className="detailsLeftCard-second-title h-26 line-seprator">
                <div className="row gx-2 hover-show-td-child-div-flex flex-nowrap align-items-center">
                  <div className="col-auto detailsLeftCard-second-title-col">
                    <div className="detailsLeftCard-second-title-item">
                      <ButtonComponent
                        type="button"
                        className="e-custom-actionbutton unset-custom-button-md link-button e-small m-0"
                      >
                        Form Editor
                      </ButtonComponent>
                    </div>
                  </div>
                  <div className="col-auto detailsLeftCard-second-title-col">
                    <div className="detailsLeftCard-second-title-item">
                      <ButtonComponent
                        type="button"
                        className="e-custombutton unset-custom-button-md link-button e-small m-0"
                      >
                        Datalist
                      </ButtonComponent>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return;
};

export const formLibraryDisplayNameTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const iconName: IconList = data["IconName"];
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const formType = data["FormType"];
  const pageType = data["PageType"];
  const category = data["Category"];
  const categoryType = data["CategoryType"];
  const createdBy = data["CreatedBy"];
  const createdDate = data["CreatedDate"];
  const modifiedDate = data["ModifiedDate"];
  const modifiedBy = data["ModifiedBy"];
  const lastActivityDate = data["LastActivityDate"];
  const currentStatus = data["CurrentStatus"];
  const tags = data["TagsListJson"];
  const formLibraryType = data["InspectionType"];
  const location = data["Location"];
  const department = data["Department"];
  const versionNumber = data["VersionNumber"];
  const frequency = data["Frequency"];
  const description = data["Description"];
  const personResponsible = data["PersonResponsible"];
  let title;
  if (isNotNullAndUndefined(description)) {
    title = description;
  } else if (isNotNullAndUndefined(formLibraryType)) {
    title = formLibraryType;
  } else {
    title = fieldValue;
  }
  let frequencyClassName = "neutral";
  if (frequency === "Daily") {
    frequencyClassName = "primary";
  } else if (frequency === "On Demand") {
    frequencyClassName = "secondary";
  } else if (frequency === "Weekly" || frequency === "Six Weekly") {
    frequencyClassName = "danger";
  } else if (frequency === "Fortnightly") {
    frequencyClassName = "quaternary";
  } else if (
    frequency === "Monthly" ||
    frequency === "Bi-Monthly" ||
    frequency === "Quarterly" ||
    frequency === "Four Monthly" ||
    frequency === "Six Monthly" ||
    frequency === "Nine Monthly"
  ) {
    frequencyClassName = "tertiary";
  } else if (
    frequency === "Yearly" ||
    frequency === "Eighteen Monthly" ||
    frequency === "Two Yearly" ||
    frequency === "Three Yearly" ||
    frequency === "Four Yearly" ||
    frequency === "Five Yearly"
  ) {
    frequencyClassName = "success";
  }

  const statusNameWithColor =
    isNotNullAndUndefined(currentStatus) &&
    isNotNullAndUndefined(RAFStatusNameWithColor[currentStatus])
      ? RAFStatusNameWithColor[currentStatus]
      : null;

  return (
    <div className="listGridItemOutter">
      <div className="listGridItemContent">
        <div
          className={`listGridItem surface_neutral_base${
            BrowserIsDevice ? " p-2-5" : " p-md-3"
          }`}
        >
          <div className="row gy-3 gx-0">
            <div className="col-12">
              <div className="row gx-2 flex-nowrap">
                {isEmptyOrNull(iconName) ? (
                  <div className="col-auto">
                    <RAFIconImage
                      iconCssClass={"fa fa-group-arrows-rotate"}
                      moduleavatar="grey-avatar avatar-text-square avatar-text transparent-avatar dashed-avatar"
                      iconSize="48"
                      fontSize="28"
                    ></RAFIconImage>
                  </div>
                ) : (
                  <div className="col-auto">
                    <span
                      className="grey-avatar avatar-text-square avatar-text transparent-avatar dashed-avatar"
                      style={{
                        width: "48px",
                        height: "48px",
                        minWidth: "48px",
                        maxWidth: "48px",
                        fontSize: "28px",
                      }}
                    >
                      <IconPickerItem icon={iconName} size={28} />
                    </span>
                  </div>
                )}
                <div className="col">
                  <div className="d-flex align-items-center">
                    <div
                      className={`formLibrary_grid_schedule_card_title ecllipseFirstLine`}
                    >
                      {title ?? "Not Set"}
                    </div>
                    {isNotNullAndUndefined(versionNumber) && (
                      <div className="ms-1">
                        <span className="raf_badge raf_badge_neutral">{`${versionNumber}`}</span>
                      </div>
                    )}
                  </div>
                  <div className="mt-1">
                    <div className="row gx-2 align-items-center flex-nowrap">
                      {BrowserIsDevice &&
                      isNotNullAndUndefined(currentStatus) ? (
                        <div className="col-auto">
                          <span
                            className="raf_badge raf_sm ecllipseFirstline"
                            style={{
                              backgroundColor: isNotNullAndUndefined(
                                statusNameWithColor
                              )
                                ? hexToRGBA(statusNameWithColor.Color, 0.1)
                                : "#333",
                              color: statusNameWithColor.Color,
                              border: `1px solid ${hexToRGBA(
                                statusNameWithColor.Color,
                                0.5
                              )}`,
                            }}
                          >
                            {isNotNullAndUndefined(statusNameWithColor)
                              ? statusNameWithColor.DisplayName
                              : isNotNullAndUndefined(currentStatus)
                              ? currentStatus
                              : "Status"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-auto">
                        <div className="body_3_light">Tags:</div>
                      </div>
                      <div className="col">
                        {isNotEmptyArray(tags) ? (
                          <RAFDetailFieldCustom
                            field="TagsListJson"
                            title="Tags"
                            value={data["TagsListJson"]}
                            enableChipArray={{
                              visible: true,
                              uiType: "Badge",
                              rowClass: "row gx-2 flex-nowrap",
                            }}
                            rowClassName="g-1"
                            showLabel={false}
                            valueClassName="body_3_light"
                          ></RAFDetailFieldCustom>
                        ) : (
                          <span className="body_2_dark">NA</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  {!BrowserIsDevice && isNotNullAndUndefined(currentStatus) ? (
                    <div className="col-auto">
                      <span
                        className="raf_badge raf_sm ecllipseFirstline"
                        style={{
                          backgroundColor: isNotNullAndUndefined(
                            statusNameWithColor
                          )
                            ? hexToRGBA(statusNameWithColor.Color, 0.1)
                            : "#333",
                          color: statusNameWithColor.Color,
                          border: `1px solid ${hexToRGBA(
                            statusNameWithColor.Color,
                            0.5
                          )}`,
                        }}
                      >
                        {isNotNullAndUndefined(statusNameWithColor)
                          ? statusNameWithColor.DisplayName
                          : isNotNullAndUndefined(currentStatus)
                          ? currentStatus
                          : "Status"}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {/* <div className="col-12">
              <div className={`row g-sm-4 g-3`}>
                <div className="col overflow-auto">
                  <RAFDetailFieldCustom
                    title="Location"
                    value={location}
                    rowClassName="gy-1 gx-2 align-items-center"
                    labelClassName="formLibrary_grid_schedule_card_label"
                    valueClassName="formLibrary_grid_schedule_card_value"
                  ></RAFDetailFieldCustom>
                </div>
                <div className="col overflow-auto">
                  <RAFDetailFieldCustom
                    title="Department"
                    value={department}
                    rowClassName="gy-1 gx-2 align-items-center"
                    labelClassName="formLibrary_grid_schedule_card_label"
                    valueClassName="formLibrary_grid_schedule_card_value"
                  ></RAFDetailFieldCustom>
                </div>
                <div className="col overflow-auto">
                  <RAFDetailFieldCustom
                    title="Person Responsible"
                    value={personResponsible}
                    rowClassName="gy-1 gx-2 align-items-center"
                    labelClassName="formLibrary_grid_schedule_card_label"
                    valueClassName="formLibrary_grid_schedule_card_value"
                  ></RAFDetailFieldCustom>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="listGridItemFooter">
          <div className="row g-2 flex-nowrap align-items-center">
            <div className="col">
              <div className="d-flex align-items-start flex-column flex-md-row">
                {!BrowserIsDevice && (
                  <div className="d-flex align-items-center flex-nowrap">
                    <div className="text_secondary_value text_secondary_value_sm ecllipseFirstLine word-break-all text-wrap">
                      Modified
                    </div>
                    <span className="pe-1 text_secondary_value text_secondary_value_sm">
                      :
                    </span>
                  </div>
                )}
                <RAFRecordInfo
                  createdBy={createdBy}
                  createdDate={createdDate}
                  modifiedDate={modifiedDate}
                  modifiedBy={modifiedBy}
                  lastActivityDate={lastActivityDate}
                  hideClockIcon={!BrowserIsDevice}
                  dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                  spanContentClassName="text_tertiary recordInfo_text fw-bold"
                ></RAFRecordInfo>
              </div>
            </div>
            {formType === RAFFormType.UserForm &&
              currentStatus === RAFBPCurrentStatus.Draft && (
                <div className="col-auto">
                  <ButtonComponent
                    type="button"
                    cssClass="e-custombutton sky_primary_outline_button"
                    content={"Edit Template"}
                    // iconCss="fas fa-play-circle"
                  ></ButtonComponent>
                </div>
              )}
            {/* {formType === RAFFormType.UserForm &&
                            currentStatus === RAFBPCurrentStatus.Published && (
                                <div className="col-auto">
                                    <ButtonComponent
                                        type="button"
                                        cssClass="e-custombutton_2 sky_primary_outline_button"
                                        content={"Records"}
                                    ></ButtonComponent>
                                </div>
                            )} */}
            {categoryType === "Recurring Form" &&
              formType === RAFFormType.UserForm &&
              currentStatus === RAFBPCurrentStatus.Published && (
                <div className="col-auto">
                  <ButtonComponent
                    type="button"
                    cssClass="e-custombutton_3 sky_primary_outline_button"
                    // content={"Show Activated"}
                    content={`Show ${RAFFormLibraryTabName.formLibrary_schedule.CollectionName}`}
                  ></ButtonComponent>
                </div>
              )}
            {categoryType === "Recurring Form" &&
              formType === RAFFormType.UserForm &&
              currentStatus === RAFBPCurrentStatus.Published && (
                <div className="col-auto">
                  <ButtonComponent
                    type="button"
                    cssClass="e-custombutton_2 sky_primary_solid_button"
                    // content={"Activate New"}
                    content={`${RAFFormLibraryTabName.formLibrary_schedule.DisplayName} New`}
                    // onClick={() => onActionMenuClicked(data, {id: 'createSchedule',
                    //         text: "Create Schedule"})}
                  ></ButtonComponent>
                </div>
              )}

            {categoryType !== "Recurring Form" &&
              (categoryType === "Form Library" ||
                categoryType === "Generic Form") &&
              formType === RAFFormType.UserForm &&
              currentStatus === RAFBPCurrentStatus.Published && (
                <>
                  <div className="col-auto">
                    <ButtonComponent
                      type="button"
                      cssClass="e-custombutton_3 sky_primary_outline_button"
                      content={`View Records`}
                    ></ButtonComponent>
                  </div>
                  <div className="col-auto">
                    <ButtonComponent
                      type="button"
                      cssClass="e-custombutton_2 sky_primary_solid_button"
                      content={`Add New`}
                    ></ButtonComponent>
                  </div>
                </>
              )}

            {formType === RAFFormType.UserForm &&
              currentStatus === RAFBPCurrentStatus.Published &&
              categoryType === "Recurring Form" && (
                <div
                  className="col-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <DropDownButtonComponent
                    cssClass={`text-nowrap e-outline${
                      BrowserIsDevice ? " e-caret-hide" : ""
                    }`}
                    items={[
                      {
                        id: "settings",
                        text: "Template Settings",
                      },
                      // {
                      //   id: "records",
                      //   text: "View Records",
                      // },
                      {
                        id: "editTemplate",
                        text: "Revise Template",
                      },
                      // {
                      //     id: 'createSchedule',
                      //     text: "Create Schedule",
                      // },
                      // {
                      //     id: "showSchedules",
                      //     text: "Show Schedules",
                      // },
                    ]}
                    {...(BrowserIsDevice
                      ? { iconCss: RAFButtonConstant.More.IconCss }
                      : {})}
                    select={(e) => {
                      if (onActionMenuClicked) {
                        onActionMenuClicked(data, e);
                      }
                    }}
                  >
                    {!BrowserIsDevice ? "More" : ""}
                  </DropDownButtonComponent>
                </div>
              )}
            {formType === RAFFormType.UserForm &&
              currentStatus === RAFBPCurrentStatus.Published &&
              categoryType !== "Recurring Form" && (
                <div
                  className="col-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <DropDownButtonComponent
                    cssClass={`text-nowrap e-outline${
                      BrowserIsDevice ? " e-caret-hide" : ""
                    }`}
                    items={[
                      {
                        id: "settings",
                        text: "Template Settings",
                      },
                      {
                        id: "editTemplate",
                        text: "Revise Template",
                      },
                    ]}
                    {...(BrowserIsDevice
                      ? { iconCss: RAFButtonConstant.More.IconCss }
                      : {})}
                    select={(e) => {
                      if (onActionMenuClicked) {
                        onActionMenuClicked(data, e);
                      }
                    }}
                  >
                    {!BrowserIsDevice ? "More" : ""}
                  </DropDownButtonComponent>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const processLibraryDisplayNameTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const iconName: IconList = data["IconName"];
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const formType = data["FormType"];
  const pageType = data["PageType"];
  const category = data["Category"];
  const categoryType = data["CategoryType"];
  const createdBy = data["CreatedBy"];
  const createdDate = data["CreatedDate"];
  const modifiedDate = data["ModifiedDate"];
  const modifiedBy = data["ModifiedBy"];
  const lastActivityDate = data["LastActivityDate"];
  const currentStatus = data["CurrentStatus"];
  const tags = data["TagsListJson"];
  const formLibraryType = data["InspectionType"];
  const location = data["Location"];
  const department = data["Department"];
  const versionNumber = data["VersionNumber"];
  const frequency = data["Frequency"];
  const description = data["Description"];
  const personResponsible = data["PersonResponsible"];

  let title;
  if (isNotNullAndUndefined(description)) {
    title = description;
  } else if (isNotNullAndUndefined(formLibraryType)) {
    title = formLibraryType;
  } else {
    title = fieldValue;
  }
  let frequencyClassName = "neutral";
  if (frequency === "Daily") {
    frequencyClassName = "primary";
  } else if (frequency === "On Demand") {
    frequencyClassName = "secondary";
  } else if (frequency === "Weekly" || frequency === "Six Weekly") {
    frequencyClassName = "danger";
  } else if (frequency === "Fortnightly") {
    frequencyClassName = "quaternary";
  } else if (
    frequency === "Monthly" ||
    frequency === "Bi-Monthly" ||
    frequency === "Quarterly" ||
    frequency === "Four Monthly" ||
    frequency === "Six Monthly" ||
    frequency === "Nine Monthly"
  ) {
    frequencyClassName = "tertiary";
  } else if (
    frequency === "Yearly" ||
    frequency === "Eighteen Monthly" ||
    frequency === "Two Yearly" ||
    frequency === "Three Yearly" ||
    frequency === "Four Yearly" ||
    frequency === "Five Yearly"
  ) {
    frequencyClassName = "success";
  }

  const statusNameWithColor =
    isNotNullAndUndefined(currentStatus) &&
    isNotNullAndUndefined(RAFStatusNameWithColor[currentStatus])
      ? RAFStatusNameWithColor[currentStatus]
      : null;

  return (
    <div className="listGridItemOutter">
      <div className="listGridItemContent">
        <div
          className={`listGridItem surface_neutral_base${
            BrowserIsDevice ? " p-2-5" : " p-md-3"
          }`}
        >
          <div className="row gy-3 gx-0">
            <div className="col-12">
              <div className="row gx-2 flex-nowrap">
                {isEmptyOrNull(iconName) ? (
                  <div className="col-auto">
                    <RAFIconImage
                      iconCssClass={"fa fa-group-arrows-rotate"}
                      moduleavatar="grey-avatar avatar-text-square avatar-text transparent-avatar dashed-avatar"
                      iconSize="48"
                      fontSize="28"
                    ></RAFIconImage>
                  </div>
                ) : (
                  <div className="col-auto">
                    <span
                      className="grey-avatar avatar-text-square avatar-text transparent-avatar dashed-avatar"
                      style={{
                        width: "48px",
                        height: "48px",
                        minWidth: "48px",
                        maxWidth: "48px",
                        fontSize: "28px",
                      }}
                    >
                      <IconPickerItem icon={iconName} size={28} />
                    </span>
                  </div>
                )}
                <div className="col">
                  <div className="d-flex align-items-center">
                    <div
                      className={`formLibrary_grid_schedule_card_title ecllipseFirstLine`}
                    >
                      {title ?? "Not Set"}
                    </div>
                    {isNotNullAndUndefined(versionNumber) && (
                      <div className="ms-1">
                        <span className="raf_badge raf_badge_neutral">{`${versionNumber}`}</span>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="row gx-2 align-items-center">
                      <div className="col-auto">
                        <div className="body_3_light">Tags:</div>
                      </div>
                      <div className="col">
                        {isNotEmptyArray(tags) ? (
                          <RAFDetailFieldCustom
                            field="TagsListJson"
                            title="Tags"
                            value={data["TagsListJson"]}
                            enableChipArray={{ visible: true, uiType: "Badge" }}
                            rowClassName="g-1"
                            showLabel={false}
                            valueClassName="body_3_light text-wrap word-break-word"
                          ></RAFDetailFieldCustom>
                        ) : (
                          <span className="body_2_dark">NA</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {!BrowserIsDevice && (
                  <div className="col-auto">
                    {isNotNullAndUndefined(currentStatus) ? (
                      <div className="col-auto">
                        <span
                          className="raf_badge raf_sm ecllipseFirstline"
                          style={{
                            backgroundColor: isNotNullAndUndefined(
                              statusNameWithColor
                            )
                              ? hexToRGBA(statusNameWithColor.Color, 0.1)
                              : "#333",
                            color: statusNameWithColor.Color,
                            border: `1px solid ${hexToRGBA(
                              statusNameWithColor.Color,
                              0.5
                            )}`,
                          }}
                        >
                          {isNotNullAndUndefined(statusNameWithColor)
                            ? statusNameWithColor.DisplayName
                            : isNotNullAndUndefined(currentStatus)
                            ? currentStatus
                            : "Status"}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="listGridItemFooter">
          <div className="row g-2 flex-nowrap align-items-center">
            <div className="col">
              <div className="d-flex align-items-start flex-column flex-md-row">
                {!BrowserIsDevice && (
                  <div className="d-flex align-items-center flex-nowrap">
                    <div className="text_secondary_value text_secondary_value_sm ecllipseFirstLine word-break-all text-wrap">
                      Modified
                    </div>
                    <span className="pe-1 text_secondary_value text_secondary_value_sm">
                      :
                    </span>
                  </div>
                )}
                <RAFRecordInfo
                  createdBy={createdBy}
                  createdDate={createdDate}
                  modifiedDate={modifiedDate}
                  modifiedBy={modifiedBy}
                  lastActivityDate={lastActivityDate}
                  hideClockIcon={!BrowserIsDevice}
                  dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                  spanContentClassName="text_tertiary recordInfo_text fw-bold"
                ></RAFRecordInfo>
              </div>
            </div>
            {formType === RAFFormType.UserForm &&
              currentStatus === RAFBPCurrentStatus.Draft && (
                <div className="col-auto">
                  <ButtonComponent
                    type="button"
                    cssClass="e-custombutton sky_primary_outline_button"
                    content={"Edit Template"}
                    // iconCss="fas fa-play-circle"
                  ></ButtonComponent>
                </div>
              )}
            {/* {formType === RAFFormType.UserForm &&
                            currentStatus === RAFBPCurrentStatus.Published && (
                                <div className="col-auto">
                                    <ButtonComponent
                                        type="button"
                                        cssClass="e-custombutton_2 sky_primary_outline_button"
                                        content={"Records"}
                                    ></ButtonComponent>
                                </div>
                            )} */}
            {formType === RAFFormType.UserForm &&
              currentStatus === RAFBPCurrentStatus.Published && (
                <div className="col-auto">
                  <RAFButtonComponent
                    action={RAFButtonConstant.Start}
                    className="e-custombutton_3 sky_success_solid_button new_style raf_sm"
                  ></RAFButtonComponent>
                </div>
              )}
            {formType === RAFFormType.UserForm &&
              currentStatus === RAFBPCurrentStatus.Published && (
                <div className="col-auto">
                  <RAFButtonComponent
                    btnContent="Monitor Status"
                    className="e-custombutton_2 new_style raf_sm e-outline"
                  ></RAFButtonComponent>
                </div>
              )}

            {formType === RAFFormType.UserForm &&
              currentStatus === RAFBPCurrentStatus.Published && (
                <div
                  className="col-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <DropDownButtonComponent
                    cssClass={`text-nowrap e-outline${
                      BrowserIsDevice ? " e-caret-hide" : ""
                    }`}
                    items={[
                      {
                        id: "runAdminMode",
                        text: "Run Admin Mode",
                      },
                      {
                        id: "settings",
                        text: "Template Settings",
                      },
                      // {
                      //   id: "records",
                      //   text: "Monitor Status",
                      // },
                      {
                        id: "editTemplate",
                        text: "Revise Template",
                      },
                      // {
                      //     id: 'createSchedule',
                      //     text: "Create Schedule",
                      // },
                      // {
                      //     id: "showSchedules",
                      //     text: "Show Schedules",
                      // },
                    ]}
                    {...(BrowserIsDevice
                      ? { iconCss: RAFButtonConstant.More.IconCss }
                      : {})}
                    select={(e) => {
                      if (onActionMenuClicked) {
                        onActionMenuClicked(data, e);
                      }
                    }}
                  >
                    {!BrowserIsDevice ? "More" : ""}
                  </DropDownButtonComponent>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const processRecordDisplayNameTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const iconName: IconList = data["IconName"];
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const createdBy = data["CreatedBy"];
  const createdDate = data["CreatedDate"];
  const modifiedDate = data["ModifiedDate"];
  const modifiedBy = data["ModifiedBy"];
  const lastActivityDate = data["LastActivityDate"];
  const tags = data["TagsListJson"];
  const inspectionType = data["InspectionType"];

  const FormDataId = data["FormDataId"];
  const FormLibrarySchedule = data["FormLibrarySchedule"];
  const FormLibrary = data["FormLibrary"];
  const CreatedBy = data["CreatedBy"];
  const CreatedDate = data["CreatedDate"];
  const FormLibraryVersionNumber = data["FormLibraryVersionNumber"];

  return (
    <div className="listGridItemOutter">
      <div className="listGridItemContent">
        <div
          className={`listGridItem surface_neutral_base${
            BrowserIsDevice ? " p-2-5" : " p-md-3"
          }`}
        >
          <div className="row gy-2 gx-0">
            <div className="col-12">
              <div className="d-flex align-items-center">
                {isNotNullAndUndefined(FormDataId) ? (
                  <div
                    className={`raf_badge raf_badge_neutral text-nowrap me-2`}
                  >
                    {isNotNullAndUndefined(FormDataId) ? FormDataId : "Not set"}
                  </div>
                ) : (
                  ""
                )}
                <div
                  className={`inspection_grid_schedule_card_title ecllipseFirstLine`}
                >
                  {FormLibrarySchedule ?? "Not Set"}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className={`row g-sm-4 g-3`}>
                <div className="col overflow-auto">
                  <RAFDetailFieldCustom
                    title="Template Name"
                    value={FormLibrary}
                    rowClassName="gy-1 gx-2 align-items-center"
                    labelClassName="inspection_grid_schedule_card_label"
                    valueClassName="inspection_grid_schedule_card_value"
                  ></RAFDetailFieldCustom>
                </div>
              </div>
            </div>
            <div className="col-12">
              <CustomCardWidget
                cardContentClassName="p-2"
                cardClassName="border-0"
              >
                <div className={`row g-sm-4 g-3`}>
                  <div className="col overflow-auto">
                    <RAFDetailFieldCustom
                      title="Created By"
                      value={CreatedBy}
                      rowClassName="gy-1 gx-2 align-items-center"
                      labelClassName="inspection_grid_schedule_card_label"
                      valueClassName="inspection_grid_schedule_card_value"
                    ></RAFDetailFieldCustom>
                  </div>
                  <div className="col overflow-auto">
                    <RAFDetailFieldCustom
                      title="Created Date"
                      value={CreatedDate}
                      rowClassName="gy-1 gx-2 align-items-center"
                      labelClassName="inspection_grid_schedule_card_label"
                      valueClassName="inspection_grid_schedule_card_value"
                      fieldFormat={{
                        type: RAFDataType.Date,
                        format: MomentFormats.DATE,
                      }}
                    ></RAFDetailFieldCustom>
                  </div>
                  <div className="col overflow-auto">
                    <RAFDetailFieldCustom
                      title="Template Version"
                      value={FormLibraryVersionNumber}
                      rowClassName="gy-1 gx-2 align-items-center"
                      labelClassName="inspection_grid_schedule_card_label ecllipseFirstLine"
                      valueClassName="body_2 ecllipseFirstLine word-break-all text-wrap"
                    ></RAFDetailFieldCustom>
                  </div>
                </div>
              </CustomCardWidget>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export class getGridColumnTemplateItems {
  static GetGridColumnTemplateItems(
    key: string,
    element: (
      moduleName: string,
      allowEditing: boolean,
      showEdit: boolean,
      item: QueryAttributeJM,
      isDynamic: boolean,
      data: any,
      navigate: any,
      onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
    ) => JSX.Element
  ) {
    return { key, element };
  }

  static defaultDropdownTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "defaultDropdownTemplate",
      defaultDropdownGridTemplate
    );
  static statusDropdownTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "statusDropdownTemplate",
      statusDropdownTemplate
    );
  static tagsTemplate = getGridColumnTemplateItems.GetGridColumnTemplateItems(
    "tagsTemplate",
    tagsGridTemplate
  );
  static dropdownTemplateAsDisc =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "dropdownTemplateAsDisc",
      dropdownTemplateAsDisc
    );
  static dropdownTemplateAsSquare =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "dropdownTemplateAsSquare",
      dropdownTemplateAsSquare
    );
  static isActiveTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "isActiveTemplate",
      isActiveTemplate
    );
  static accountLookupTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "accountLookupTemplate",
      accountLookupTemplate
    );
  static dateTemplate = getGridColumnTemplateItems.GetGridColumnTemplateItems(
    "dateTemplate",
    dateGridTemplate
  );
  static dateTimeTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "dateTimeTemplate",
      dateTimeGridTemplate
    );
  static descriptionTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "descriptionTemplate",
      descriptionGridTemplate
    );
  static contactLookupTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "contactLookupTemplate",
      contactLookupTemplate
    );
  static documentFileSizeTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "documentFileSizeTemplate",
      documentFileSizeTemplate
    );
  static documentDisplayNameTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "documentDisplayNameTemplate",
      documentDisplayNameTemplate
    );
  static documentRowDisplayNameTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "documentRowDisplayNameTemplate",
      documentRowDisplayNameTemplate
    );
  static relatedDocumentRowDisplayNameTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "relatedDocumentRowDisplayNameTemplate",
      relatedDocumentRowDisplayNameTemplate
    );
  static documentTableDisplayNameTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "documentTableDisplayNameTemplate",
      documentTableDisplayNameTemplate
    );
  static DocumentCategoryTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "DocumentCategoryTemplate",
      DocumentCategoryTemplate
    );
  static DocumentTableCategoryTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "DocumentTableCategoryTemplate",
      DocumentTableCategoryTemplate
    );
  static documentActionButtonTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "documentActionButtonTemplate",
      documentActionButtonTemplate
    );
  static documentDisplayNameListTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "documentDisplayNameListTemplate",
      documentDisplayNameListTemplate
    );
  static versionHistoryTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "versionHistoryTemplate",
      versionHistoryTemplate
    );
  static versionHistoryListTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "versionHistoryListTemplate",
      versionHistoryListTemplate
    );
  static checklistItemTransVersionHistoryListTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "checklistItemTransVersionHistoryListTemplate",
      checklistItemTransVersionHistoryListTemplate
    );
  static revisionListTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "revisionListTemplate",
      revisionListTemplate
    );
  static revisionTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "revisionTemplate",
      revisionTemplate
    );
  static careRecipientTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "careRecipientTemplate",
      careRecipientTemplate
    );
  static careRecipientFirstNameTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "careRecipientFirstNameTemplate",
      careRecipientFirstNameTemplate
    );
  static careRecipientTableRowTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "careRecipientTableRowTemplate",
      careRecipientTableRowTemplate
    );
  static careRecipientFirstNameTableRowTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "careRecipientFirstNameTableRowTemplate",
      careRecipientFirstNameTableRowTemplate
    );
  static employeeTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "employeeTemplate",
      employeeTemplate
    );
  static employeeTableRowTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "employeeTableRowTemplate",
      employeeTableRowTemplate
    );
  static employeeIntegrationRefTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "employeeIntegrationRefTemplate",
      employeeIntegrationRefTemplate
    );
  static TeamNameTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "TeamNameTemplate",
      TeamNameTemplate
    );
  static firstColumnTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "firstColumnTemplate",
      firstColumnGridTemplate
    );
  static taskDateColumnTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "taskDateColumnTemplate",
      taskDateColumnTemplate
    );
  static taskTitleColumnTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "taskTitleColumnTemplate",
      taskTitleColumnTemplate
    );
  static taskTitleRowTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "taskTitleRowTemplate",
      taskTitleRowTemplate
    );
  static taskTitleWithReassigneeColumnTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "taskTitleWithReassigneeColumnTemplate",
      taskTitleWithReassigneeColumnTemplate
    );
  static taskTitleWithReassigneeRowTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "taskTitleWithReassigneeRowTemplate",
      taskTitleWithReassigneeRowTemplate
    );
  static taskStatusTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "taskStatusTemplate",
      taskStatusTemplate
    );
  static relatedTaskToLookupTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "relatedTaskToLookupTemplate",
      relatedTaskToLookupTemplate
    );
  static updatedDateTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "updatedDateTemplate",
      updatedDateTemplate
    );
  static dueDateTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "dueDateTemplate",
      dueDateTemplate
    );
  static emailDateTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "emailDateTemplate",
      emailDateTemplate
    );
  static emailRelatedToTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "emailRelatedToTemplate",
      emailRelatedToTemplate
    );
  static isBooleanTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "isBooleanTemplate",
      isBooleanGridTemplate
    );
  static lookupMultiSelectTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "lookupMultiSelectTemplate",
      lookupMultiSelectGridTemplate
    );
  static lookupTemplate = getGridColumnTemplateItems.GetGridColumnTemplateItems(
    "lookupTemplate",
    lookupGridTemplate
  );
  static riskmatrixJsonTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "riskmatrixJsonTemplate",
      riskmatrixJsonTemplate
    );
  static bodypartpickerTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "bodypartpickerTemplate",
      bodypartpickerTemplate
    );
  static wsTemplate = getGridColumnTemplateItems.GetGridColumnTemplateItems(
    "wsTemplate",
    wsTemplate
  );
  static workFlowStatus = getGridColumnTemplateItems.GetGridColumnTemplateItems(
    "workFlowStatus",
    workFlowStatus
  );
  static processTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "processTemplate",
      processTemplate
    );
  static progressBarTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "progressBarTemplate",
      progressBarTemplate
    );
  static bpTemplate = getGridColumnTemplateItems.GetGridColumnTemplateItems(
    "bpTemplate",
    bpTemplate
  );
  static bpRowTemplate = getGridColumnTemplateItems.GetGridColumnTemplateItems(
    "bpRowTemplate",
    bpRowTemplate
  );
  static formLibraryTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "formLibraryTemplate",
      formLibraryTemplate
    );
  static ViewTypeViewDisplayName =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "ViewTypeViewDisplayName",
      ViewTypeViewDisplayName
    );
  static ViewTypeViewTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "ViewTypeViewTemplate",
      ViewTypeViewTemplate
    );
  static visibilityViewTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "visibilityViewTemplate",
      visibilityViewTemplate
    );
  static showInListViewTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "showInListViewTemplate",
      showInListViewTemplate
    );
  static formLibraryNameTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "formLibraryNameTemplate",
      formLibraryNameTemplate
    );
  static formLibraryDisplayNameTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "formLibraryDisplayNameTemplate",
      formLibraryDisplayNameTemplate
    );
  static processLibraryDisplayNameTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "processLibraryDisplayNameTemplate",
      processLibraryDisplayNameTemplate
    );
  static processRecordDisplayNameTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "processRecordDisplayNameTemplate",
      processRecordDisplayNameTemplate
    );
  static complainceTableRowTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "complainceTableRowTemplate",
      complainceTableRowTemplate
    );
  static complainceListRowTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "complainceListRowTemplate",
      complainceListRowTemplate
    );
  static expiryDateTemplate =
    getGridColumnTemplateItems.GetGridColumnTemplateItems(
      "expiryDateTemplate",
      expiryDateTemplate
    );
}
